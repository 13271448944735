import React from "react"
import { notify } from "react-notify-toast"
import { Prompt } from "react-router"

class ConditionForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      item: props.item,
      condition: props.condition || {},
      didChange: false
    }

    this.onEqualityChanged = this.onEqualityChanged.bind(this)
    this.onValueChanged = this.onValueChanged.bind(this)
    this.onInstructionsChanged = this.onInstructionsChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSubmitAndAnother = this.onSubmitAndAnother.bind(this)
    this.onSkip = this.onSkip.bind(this)
  }

  componentWillReceiveProps(props) {
    this.setState({
      item: props.item,
      condition: props.condition || { equality: '', value: '', instructions: '' }
    })
  }

  validate() {
    return !(
      this.state.condition.equality == null ||
      this.state.condition.equality === '' ||
      this.state.condition.value == null ||
      this.state.condition.value === '' ||
      this.state.condition.instructions == null ||
      this.state.condition.instructions === ''
    )
  }

  onAddCondition(another) {
    if (this.validate()) {
      this.props.onNext(this.state.condition, another)
    } else {
      notify.show('Please check fields', 'error')
    }
  }

  onSubmit(event) {
    event.preventDefault()
    this.onAddCondition(false)
  }

  onSubmitAndAnother(event) {
    event.preventDefault()
    this.onAddCondition(true)
  }

  onSkip(event) {
    event.preventDefault()
    this.props.onSkip()
  }

  onEqualityChanged(event) {
    const { condition } = this.state
    condition.equality = event.target.value
    this.setState({ condition })
    this.setState({didChange: true})
  }

  onValueChanged(event) {
    const { condition } = this.state
    condition.value = event.target.value
    this.setState({ condition })
    this.setState({didChange: true})
  }

  onInstructionsChanged(event) {
    const { condition } = this.state
    condition.instructions = event.target.value
    this.setState({ condition })
    this.setState({didChange: true})
  }

  renderSelect(items) {
    return (
      <select name="value" className="form-control form-control-lg" value={this.state.condition.value} onChange={this.onValueChanged}>
        <option>Choose one...</option>
        {items.map(option => <option value={option.value}>{option.name}</option>)}
      </select>
    )
  }

  renderEqualitySelect(items) {
    return (
      <select name="equality" className="form-control form-control-lg" value={this.state.condition.equality} onChange={this.onEqualityChanged}>
        <option>Choose one...</option>
        {items.map(option => <option value={option.value}>{option.name}</option>)}
      </select>
    )
  }  

  render () { 
    let value_selector = (
      <input value={this.state.condition.value} onChange={this.onValueChanged} type="text" className="form-control form-control-lg" id="value" aria-describedby="emailHelp" placeholder="Condition" />
    )
    if (this.state.item.item_type === 'selection' || this.state.item.item_type === 'multiple_selection') {
      value_selector = this.renderSelect((this.state.item.config || []).map((c) => { return {name: c, value: c} }))
    }
    if (this.state.item.item_type === 'yes_no') {
      value_selector = this.renderSelect([{name: 'Yes', value: 'yes'}, {name: 'No', value: 'no'}])
    }

    let equality_selector = (
      <select value={this.state.condition.equality} onChange={this.onEqualityChanged} className="form-control form-control-lg" id="equality" />
    )
    if (this.state.item.item_type === 'selection' || this.state.item.item_type === 'multiple_selection' || this.state.item.item_type === 'text' || this.state.item.item_type === 'expanded_text') {
      equality_selector = this.renderEqualitySelect([{name: 'Matches', value: 'matches'}, {name: 'Does not contain', value: 'does_not_contain'}])
    }
    if (this.state.item.item_type === 'yes_no') {
      equality_selector = this.renderEqualitySelect([{name: 'Matches', value: 'matches'}])
    }
    if (this.state.item.item_type === 'quantity' || this.state.item.item_type === 'temperature') {
      equality_selector = this.renderEqualitySelect([{name: 'Is less than', value: 'less_than'}, {name: 'Is greater than', value: 'greater_than'}, {name: 'Is equal to', value: 'is_equal_to'}])
    }    

    return (
      <>
        <Prompt
          when={this.state.didChange}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">Let's set up a condition.</h4>
                      <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <label for=""><h6>If the answer...</h6></label>
                            {equality_selector}
                        </div>
                        <div className="form-group">
                          {value_selector}
                        </div>
                        <div className="form-group">
                          <label for=""><h6>...add this to the punchlist:</h6></label>
                          <textarea value={this.state.condition.instructions} onChange={this.onInstructionsChanged} type="text" className="form-control form-control-lg" id="instructions" aria-describedby="emailHelp" placeholder="Enter instructions for next steps." />
                        </div>
                        <br />
                        <button type="submit" className="btn btn-primary btn-lg btn-block">Next</button>
                        <button onClick={this.onSubmitAndAnother} className="btn btn-primary btn-lg btn-block">Next and add another</button>
                        <button onClick={this.onSkip} className="btn btn-light btn-lg btn-block">Skip</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default ConditionForm
