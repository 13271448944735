import React from "react"

class UserForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      full_name: '',
      password: '',
      password_confirmation: '',
      role: '',
      deactivated: props.deactivated || false,
      disabled: true,
      ...this.props.employee
    }

    this.onEmailChanged = this.onEmailChanged.bind(this)
    this.onFullNameChanged = this.onFullNameChanged.bind(this)
    this.onPasswordChanged = this.onPasswordChanged.bind(this)
    this.onPasswordConfirmationChanged = this.onPasswordConfirmationChanged.bind(this)
    this.onRoleChanged = this.onRoleChanged.bind(this)
    this.onDeactivatedChanged = this.onDeactivatedChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.confirmFullNameExists = this.confirmFullNameExists.bind(this)
    this.fullNameInput = React.createRef();
    this.refreshPage = this.refreshPage.bind(this)
  }

  onSubmit(event) {
    event.preventDefault()

    this.props.onSave && this.props.onSave(this.state.email, this.state.full_name, this.state.role, this.state.password, this.state.password_confirmation, this.state.deactivated)
  }

  onEmailChanged(event) {
    this.setState({email: event.target.value})
  }

  onFullNameChanged(event) {
    this.setState({full_name: event.target.value})
  }

  onPasswordChanged(event) {
    this.setState({password: event.target.value})
  }

  onPasswordConfirmationChanged(event) {
    this.setState({password_confirmation: event.target.value})
  }

  onRoleChanged(event) {
    this.setState({role: event.target.value})
  }

  onDeactivatedChanged(event) {
    this.setState({deactivated: event.target.checked})
  }  

  refreshPage() {
    console.log("REFRESH PAGE")
    window.location.reload();
  }

  confirmFullNameExists(event){
    console.log("CHECKING FULL NAME")
    const fullnamelen  = event.target.value.length 
    console.log(fullnamelen)
     if (event.target.value.length == 0) {
       alert("The user's full name is required before continuing");
       this.refreshPage()
     } else {
      this.setState({disabled: false})
     }    
  };      

  render () {
    const roles = this.props.currentUser.creatable_roles.map((role) => {
      return (
        <option key={role} value={role}>{role}</option>
      )
    })
    let passwordFields = null
    if (this.props.showPasswordFields) {
      passwordFields = (
        <>
          <div className="form-group">
            <input value={this.state.password} onChange={this.onPasswordChanged} type="password" className="form-control form-control-lg" id="password" placeholder="Password" disabled={this.state.disabled}/>
          </div>
          <div className="form-group">
            <input value={this.state.passwordConfirmation} onChange={this.onPasswordConfirmationChanged} type="password" className="form-control form-control-lg" id="password_confirmation" placeholder="Confirm password" disabled={this.state.disabled}/>
          </div>
        </>
      )
    }
    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <input value={this.state.full_name} onBlur={this.confirmFullNameExists} ref={this.fullNameInput} autoFocus={true} onChange={this.onFullNameChanged} type="text" className="form-control form-control-lg" id="full_name" aria-describedby="emailHelp" placeholder="Enter name" required/>
        </div>      
        <div className="form-group">
          <input value={this.state.email} onChange={this.onEmailChanged} type="text" className="form-control form-control-lg" id="email" aria-describedby="emailHelp" placeholder="Enter email" disabled={this.state.disabled}/>
        </div>
        {passwordFields}
        <div className="form-group">
          <select value={this.state.role} onChange={this.onRoleChanged} className="form-control form-control-lg" id="role" disabled={this.props.currentUser.role != "admin" || this.state.disabled}>
            <option>Choose one...</option>
            {roles}
          </select>
        </div>
        <div className="form-check">
          <input checked={this.state.deactivated} onChange={this.onDeactivatedChanged} className="form-check-input" type="checkbox" id="deactivated" disabled={this.props.currentUser.role != "admin" }/>
          <label className="form-check-label" htmlFor="deactivated">
            Deactivated?
          </label>
        </div><br />
        <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={this.state.disabled}>Save</button>
      </form>
    );
  }
}

export default UserForm
