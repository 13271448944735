import React from "react"
import { Link } from "react-router-dom"

class ListAdminLinks extends React.Component {
  constructor(props) {
    super(props)

  }

  render() {
    return (
      <>
        <br />
        <Link to={`/lists/${this.props.list.id}/edit`}>Edit checklist</Link>
        <br />
        <Link onClick={this.onDelete}>Delete checklist</Link>
        <br /> 
      </>
    )
  }
}

export default ListAdminLinks