import React from "react"
import { Link } from "react-router-dom"
import { notify } from "react-notify-toast"
import LoggedInPage from "./LoggedInPage"
import Log from "../services/log"
import Logs from "../components/Logs"
import PageHeader from "../components/PageHeader"

class LogsPage extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      logs: [],
      list_id: props.match.params.list_id,
      isLoading: true
    }

    Log.all(this.props.currentUser, this.state.list_id)
      .then((json) => {
        this.setState({ logs: json, isLoading: false })
      }).catch((error) => {
        notify.show("Logs not found", 'error')
        this.setState({ isLoading: false })
      })
  }

  render () {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <PageHeader title="Logs" currentUser={this.props.currentUser} />
            <Logs isLoading={this.state.isLoading} logs={this.state.logs} />
          </div>
        </div>
      </>
    );
  }
}

export default LogsPage
