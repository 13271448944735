import React from 'react';
import PageHeader from "../components/PageHeader"

const NotFound = () => (
	<div className="row justify-content-center">
	  <div className="col-11 col-md-11">
	    <PageHeader />
	    <div className="card">
	      <div className="card-body">
				  <div className="main-content not-found">
				    <i className="material-icons icn-error">error_outline</i>
				    <h2>Page Not Found</h2>
				  </div>
				</div>
	    </div>
	  </div>
	</div>
);

export default NotFound;