import React from "react"
import { Link, withRouter } from "react-router-dom"
import { notify } from "react-notify-toast"
import Avatar from "./Avatar"
import Auth from "../services/auth"

class Sidebar extends React.Component {
  constructor(props) {
    super(props)

    this.onSignOut = this.onSignOut.bind(this)
  }

  onSignOut() {
    Auth.signOut(this.props.currentUser)
      .then(() => {
        this.props.setCurrentUser(null)
        notify.show('Signed out', 'success')
        this.props.history.push('/')
      })
  }

  renderUserAvatarDropdown(mobile = false) {
    let userAvatar = null
    if (this.props.isLoggedIn) {
      userAvatar = (
        <div className={mobile ? "d-block d-lg-none ml-2" : "d-none d-lg-block"}>
          <div className={mobile ? "dropdown" : "dropup"}>
            <a href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <Avatar user={this.props.currentUser} />
            </a>
            <div className={mobile ? "dropdown-menu dropdown-menu-right" : "dropdown-menu"}>
              <Link to={`/employees/${this.props.currentUser.id}/edit`} className="dropdown-item">Profile</Link>
              {this.props.currentUser.role === "admin" && <Link to="/account" className="dropdown-item">Account Settings</Link> }
              <a className="dropdown-item" href="#" onClick={this.onSignOut}>Sign out</a>
            </div>
          </div>
        </div>
      )
    }
    return userAvatar
  }

  render () {
    let navItems = (
      <>
        <Link to="/" className="nav-link">Home</Link>
        <Link to="/sign-in" className="nav-link">Sign in</Link>
      </>
    )
    if (this.props.isLoggedIn) {
      navItems = (
        <>
          <Link to="/dashboard" className="nav-link">Dashboard</Link>
          { this.props.currentUser.role.includes("admin","supervisor") && <Link to="/locations" className="nav-link">Locations</Link> }
          { this.props.currentUser.role === "admin" && <Link to="/templates" className="nav-link">Checklist templates</Link> }
          { this.props.currentUser.role.includes("admin","supervisor") && <Link to="/employees" className="nav-link">Employees</Link> }
          { this.props.currentUser.role === "admin" && <Link to="/gettingstarted" className="nav-link">Getting Started</Link> }
          <a className="nav-link" href="#" onClick={this.onSignOut}>Sign out</a>
        </>
      )
    }

    let currentUser = null
    if (this.props.isLoggedIn && this.props.currentUser !== null) {
      currentUser = (
        <li className="nav-item">{this.props.currentUser.email}</li>
      )
    }

    return (
      <>
        <div className="d-flex align-items-center">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          {this.renderUserAvatarDropdown(true)}
        </div>
        <div className="collapse navbar-collapse flex-column" id="navbar-collapse">
          <ul className="navbar-nav d-lg-block">
            {currentUser}
            <li className="nav-item" data-toggle="collapse" data-target="#navbar-collapse.show">
              {navItems}
            </li>
          </ul>
          <hr />
        </div>
        {this.renderUserAvatarDropdown()}
      </>
    );
  }
}

export default withRouter(Sidebar)
