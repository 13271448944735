import React from "react"
import { Link } from "react-router-dom"
import { notify } from "react-notify-toast"
import queryString from "query-string"
import Auth from "../services/auth"

class SignIn extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      remember_me: false,
      showConfirmation: this.props.location && this.props.location.search && queryString.parse(this.props.location.search).account_confirmation_success
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onEmailChanged = this.onEmailChanged.bind(this)
    this.onPasswordChanged = this.onPasswordChanged.bind(this)
    this.onRememberMeChanged = this.onRememberMeChanged.bind(this)
  }

  onSubmit(event) {
    event.preventDefault();
    let accessToken = null
    let client = null
    Auth.signIn(this.state.email, this.state.password)
      .then((response) => {
        accessToken = response.headers.get('access-token')
        client = response.headers.get('client')
        return response.json()
      })
      .then((json) => {
        if(!!json.errors) {
          notify.show(`Error signing in - ${json.errors.join(', ')}`, 'error')
        } else {
          let user = json
          user.access_token = accessToken
          user.client = client
          notify.show(`Logged in as ${user.email}`, 'success')
          user.remember_me = this.state.remember_me
          this.props.setCurrentUser(user)
          this.props.history.push('/')
        }
      })
      .catch((error) => {
        notify.show(`Error signing in - ${error}`, 'error')
      })
  }

  onEmailChanged(event) {
    this.setState({email: event.target.value});
  }

  onPasswordChanged(event) {
    this.setState({password: event.target.value});
  }

  onRememberMeChanged(event) {
    this.setState({remember_me: event.target.checked})
  }

  render () {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-md-11">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-center">{this.state.showConfirmation ? 'User email confirmed! ' : ''}Sign in below.</h4>
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <input value={this.state.email} onChange={this.onEmailChanged} type="email" className="form-control form-control-lg" id="email" aria-describedby="emailHelp" placeholder="Enter email" />
                  </div>
                  <div className="form-group">
                    <input value={this.state.password} onChange={this.onPasswordChanged} type="password" className="form-control form-control-lg" id="password" placeholder="Password" />
                  </div>
                  <div className="form-group">
                    <input checked={this.state.remember_me} onChange={this.onRememberMeChanged} type="checkbox" className="form-control" id="remember_me" name="remember_me" aria-describedby="emailHelp" style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
                    <label htmlFor="remember_me" style={{'float': 'left', 'marginTop': '4px'}}> Remember me</label>
                    <br />
                  </div>
                  <button type="submit" className="btn btn-primary btn-lg btn-block">Sign in</button>
                  <p className="small"><em>By signing in, you agree to our <a href="https://linecheck.app/tos" target="_blank">Terms of Service</a>.</em></p>
                </form>
                <br />
                <Link to="/sign-up">Need to create an account? Sign up!</Link>
                <br />
                <Link to="/forgot-password">Forgotten your password?</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SignIn
