import API from './api'

const Schedule = {
  all: function(user, list_id) {
    return API.get(user, `lists/${list_id}/schedules`)
  },

  find: function(user, list_id, id) {
    return API.get(user, `lists/${list_id}/schedules/${id}`)
  },

  create: function(user, list_id, starts_at, ends_at, schedule_type, days) {
    return API.post(user, `lists/${list_id}/schedules`, { schedule: { starts_at, ends_at, schedule_type, days } })
  },

  update: function(user, list_id, schedule, starts_at, ends_at, schedule_type, days) {
    return API.put(user, `lists/${list_id}/schedules/${schedule.id}`, { schedule: { starts_at, ends_at, schedule_type, days } })
  }
}

export default Schedule