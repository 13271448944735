import React from "react"
import { Link } from "react-router-dom"

class Home extends React.Component {
  constructor(props) {
    super(props)

    if (props.isLoggedIn) {
      props.history && props.history.push('/pricing')
    }
  }
  render () {
    return (
      <>
        <h4 className="text-center">Pricing</h4>
        <div class="row">
          <div class="col-6">
            <ul class="list-group text-center">
              <li class="list-group-item active">Monthly</li>
              <li class="list-group-item">1 location: Free</li>
              <li class="list-group-item">2-5 locations: $20 each</li>
              <li class="list-group-item">6-10 locations: $19 each</li>
              <li class="list-group-item">11-15 locations: $18 each</li>
              <li class="list-group-item">16-20 locations: $17 each</li>
              <li class="list-group-item">21-25 locations: $16 each</li>
              <li class="list-group-item">26+ locations: $15 each</li>
            </ul>
          </div>
          <div class="col-6">
            <ul class="list-group text-center">
              <li class="list-group-item active">Annual</li>
              <li class="list-group-item">1 location: Free</li>
              <li class="list-group-item">2-5 locations: $200 each</li>
              <li class="list-group-item">6-10 locations: $190 each</li>
              <li class="list-group-item">11-15 locations: $180 each</li>
              <li class="list-group-item">16-20 locations: $170 each</li>
              <li class="list-group-item">21-25 locations: $160 each</li>
              <li class="list-group-item">26+ locations: $150 each</li>
            </ul>
          </div>
        </div>
        <hr />
      </>
    );
  }
}

export default Home