import React from "react"
import { notify } from "react-notify-toast"
import LoggedInPage from "./LoggedInPage"
import Employee from "../services/employee"
import UserForm from "../components/UserForm"

class EditEmployee extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      employee: null,
      isLoading: true
    }

    this.loadEmployee(props)

    this.onSave = this.onSave.bind(this)
  }

  componentWillReceiveProps(props) {
    this.loadEmployee(props)
  }

  loadEmployee(props) {
    this.setState({ isLoading: true })
    Employee.find(props.currentUser, props.match.params.id)
      .then((json) => {
        this.setState({ employee: json, isLoading: false })
      }).catch((error) => {
        notify.show("Employee not found", 'error')
        this.setState({ isLoading: false })
      })
  }

  onSave(email, full_name, role, password, password_confirmation, deactivated) {
    Employee.update(
      this.props.currentUser,
      this.state.employee,
      email,
      full_name,
      role,
      password,
      password_confirmation,
      deactivated
    )
    .then((json) => {
      notify.show('Employee saved', 'success')
      this.props.history && this.props.history.push('/employees')
    })
    .catch((error) => {
      notify.show(error.message, 'error')
    })
  }

  render () {
    let employee = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.employee) {
        employee = (
          <UserForm {...this.props} onSave={this.onSave} employee={this.state.employee} showPasswordFields={this.state.employee.id === this.props.currentUser.id} />
        )
      } else {
        employee = (
          <p>Unable to find that employee.</p>
        )
      }
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">{this.state.employee && this.props.currentUser && this.state.employee.id === this.props.currentUser.id ? "Edit profile" : "Employee Info"}</h4>
                      {employee}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default EditEmployee
