import React from "react"

class ListView extends React.Component {
  constructor(props) {
    super(props)

    this.onResponse = this.onResponse.bind(this)

    let values = {}
    if (props.list.items) {
      (props.list.items).forEach((item) => {
        if (item.response) {
          values[item.id] = item.response
        }
        if (!item.conditions_attributes) {
          item.conditions_attributes = item.conditions
        }
      })
      if (!props.list.items_attributes) {
        props.list.items_attributes = props.list.items
      }
    }

    this.state = {
      readonly: props.readonly || false,
      responses: {},
      values,
      evidences: {},
      allowEditItems: props.onEditItem || false,
      allowEditConditions: props.onEditCondition || false,
      allowDeleteItems: props.onDeleteItem || false,
      allowDeleteConditions: props.onDeleteCondition || false
    }
  }

  onResponse(event) {
    let { responses, values, evidences } = this.state

    const item_id = parseInt(event.target.name.split("_")[1])
    const item = this.props.list.items.filter(item => item.id === item_id)[0]

    let value = event.target.value
    if (item.item_type === 'multiple_selection') {
      value = []
      document.getElementsByName(event.target.name)
        .forEach(item => item.checked && value.push(item.value))
    }

    if (event.target.files) {
      evidences[item_id] = event.target.files[0]
      value = values[item_id]
    }

    values[item_id] = value

    responses[item_id] = {
      config: item.config,
      id: item.id,
      item_type: item.item_type,
      prompt: item.prompt,
      requires_photo: item.requires_photo,
      created_at: item.created_at,
      updated_at: item.updated_at,
      response: value
    }

    this.setState({ responses, values, evidences })

    this.props.onResponsesUpdated && this.props.onResponsesUpdated(responses, evidences)
  }

  getValue(item_id, multi = false) {
    return (item_id && this.state.values && this.state.values[item_id]) || (multi ? [] : '')
  }

  renderItem(item) {
    if (item._destroy) {
      return null
    }
    let field = null
    switch(item.item_type) {
      case 'text':
        field = (
          <input type="text" value={this.getValue(item.id)} onChange={this.onResponse} name={`item_${item.id}`} className="form-control form-control-lg" aria-describedby="emailHelp" placeholder="" disabled={this.state.readonly} />
        )
      break
      case 'expanded_text':
        field = (
          <textarea type="text" value={this.getValue(item.id)} onChange={this.onResponse} name={`item_${item.id}`} className="form-control form-control-lg" aria-describedby="emailHelp" placeholder="" disabled={this.state.readonly} />
        )
      break
      case 'yes_no':
        field = (
          <>
            <div className="form-check">
              <input className="form-check-input" type="radio" checked={this.getValue(item.id) === 'yes'} onChange={this.onResponse} name={`item_${item.id}`} id={`item_${item.id}`} value="yes" disabled={this.state.readonly} />
              <label className="form-check-label" htmlFor={`item_${item.id}`}>
                Yes
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" checked={this.getValue(item.id) === 'no'} onChange={this.onResponse} name={`item_${item.id}`} id={`item_${item.id}`} value="no" disabled={this.state.readonly} />
              <label className="form-check-label" htmlFor={`item_${item.id}`}>
                No
              </label>
            </div>
          </>
        )
      break
      case 'selection':
        const select_options = item.config && item.config.map((option) => {
          return (
            <div class="form-check">
              <input class="form-check-input" type="radio" checked={this.getValue(item.id) === option} onChange={this.onResponse} name={`item_${item.id}`} id={`item_${item.id}_${option}`} value={option} disabled={this.state.readonly} />
              <label class="form-check-label" htmlFor={`item_${item.id}_${option}`}>
                {option}
              </label>
            </div>
          )
        }) || []
        field = (
          <>
            {select_options}
          </>
        )
      break
      case 'multiple_selection':
        const multi_select_options = item.config && item.config.map((option, index) => {
          return (
            <>
              <div style={{'clear': 'both'}}>
                <input value={option} checked={this.getValue(item.id, true).includes(option)} onChange={this.onResponse} type="checkbox" className="form-control form-control-lg" id={`item_${item.id}_${index}`} name={`item_${item.id}`} aria-describedby="emailHelp" disabled={this.state.readonly} style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
                <label htmlFor={option} style={{'float': 'left', 'margin-top': '4px', 'margin-bottom': '0'}}>{option}</label>
              </div>
            </>
          )
        }) || []
        field = (
          <>
            {multi_select_options}
            <br />
          </>
        )
      break
      case 'quantity':
        field = (
          <input type="text" value={this.getValue(item.id)} onChange={this.onResponse} name={`item_${item.id}`} className="form-control form-control-lg" aria-describedby="emailHelp" placeholder={`Enter quantity${item.config && item.config[0] ? ' in ' + item.config[0] : ''}`} disabled={this.state.readonly} />
        )
      break
      case 'temperature':
        field = (
          <input type="text" value={this.getValue(item.id)} onChange={this.onResponse} name={`item_${item.id}`} className="form-control form-control-lg" aria-describedby="emailHelp" placeholder="Enter temperature" disabled={this.state.readonly} />
        )
      break
    }
    let photo_upload = null
    if (item.requires_photo) {
      photo_upload = (
        <>
          <p className="small"><em>Photo Evidence Required</em></p>
          {this.state.readonly ? null : <input type="file" accept="image/*" capture="camera" onChange={this.onResponse} name={`item_${item.id}_evidence`} className="form-control form-control-lg" aria-describedby="emailHelp" />} 
          {item.evidence ? <a href={item.evidence.medium} target="_blank"><img src={item.evidence.thumb} /></a> : null}
          <br />
        </>
      )
    }
    let editItem = null
    if (item.id != null && this.state.allowEditItems) {
      editItem = (
        <a href="#" onClick={() => this.props.onEditItem(item)}>Edit item</a>
      )
    }
    let deleteItem = null
    if (this.state.allowDeleteItems) {
      deleteItem = (
        <a href="#" onClick={() => this.props.onDeleteItem(item)}>Remove item</a>
      )
    }
    let conditions = null
    if (this.state.readonly && (item.conditions || item.conditions_attributes || []).filter(c => !c._destroy).length > 0) {
      conditions = (
        <>
          <i>Conditions</i>
          <ul>
            {(item.conditions || item.conditions_attributes).map(condition => this.renderCondition(item, condition))}
          </ul>
        </>
      )
    }
    return (
      <div className="form-group">
        <label htmlFor=""><h6>{item.prompt}</h6></label>
        {photo_upload}
        {field}
        <p>
          {editItem}
          <br />
          {deleteItem}
        </p>
        {conditions}
      </div>
    )
  }

  renderCondition(item, condition) {
    if (condition._destroy) {
      return null
    }
    let editCondition = null
    if (condition.id != null && this.state.allowEditConditions) {
      editCondition = (
        <a href="#" onClick={() => this.props.onEditCondition(item, condition)}>Edit condition</a>
      )
    }
    let deleteCondition = null
    if (this.state.allowDeleteConditions) {
      deleteCondition = (
        <a href="#" onClick={() => this.props.onDeleteCondition(item, condition)}>Remove condition</a>
      )
    }
    return (
      <li>
        If the answer is {condition.equality} {condition.value} then add this to the punchlist:
        <pre>{condition.instructions}</pre>
        <p>
          {editCondition}
          <br />
          {deleteCondition}
        </p>
      </li>
    )
  }

  render() {
    return this.props.list.items_attributes.map(item => this.renderItem(item))
  }
}

export default ListView
