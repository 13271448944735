import React from "react"
import { notify } from "react-notify-toast"
import LoggedInPage from "./LoggedInPage"
import Log from "../services/log"
import { formatDateTime } from "../services/utils"
import ListView from "../components/ListView"
import PageHeader from "../components/PageHeader"

class LogPage extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      log: null,
      list_id: props.match.params.list_id,
      isLoading: true
    }

    Log.find(this.props.currentUser, this.state.list_id, props.match.params.id)
      .then((json) => {
        const responses = json.responses
        json.evidences.forEach((evidence) => {
          if (responses[evidence.item_id]) {
            responses[evidence.item_id].evidence = evidence
          }
        })
        const list = {
          name: json.list_name,
          items: Object.values(responses)
        }
        this.setState({ list, log: json, isLoading: false })
      }).catch((error) => {
        notify.show("Log not found", 'error')
        this.setState({ isLoading: false })
      })
  }

  render () {
    let log = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.log) {
        let punchlist = (
          <li>No punchlist as a result of this checklist log</li>
        )
        if (this.state.log.punchlist && this.state.log.punchlist.length > 0) {
          punchlist = this.state.log.punchlist.map(text => <li>{text}</li>)
        }
        log = (
          <>
            <p>
              Logged by {this.state.log.user.full_name} ({this.state.log.user.email}) at {formatDateTime(this.state.log.created_at)}
            </p>
            <hr />
            <ListView list={this.state.list} readonly={true} />
            <hr />
            <h4>Punchlist</h4>
            <ul>
              {punchlist}
            </ul>
          </>
        )
      } else {
        log = (
          <p>Unable to find that log for this checklist.</p>
        )
      }
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <PageHeader title="Log" currentUser={this.props.currentUser} />
            {log}
          </div>
        </div>
      </>
    );
  }
}

export default LogPage
