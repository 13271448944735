import React from "react"
import { notify } from "react-notify-toast"
import LoggedInPage from "./LoggedInPage"
import ItemForm from "../components/ItemForm"
import ConditionForm from "../components/ConditionForm"
import ItemPrompt from "../components/ItemPrompt"
import ListReview from "../components/ListReview"
import ListForm from "../components/ListForm"
import ScheduleForm from "../components/ScheduleForm"
import List from "../services/list"
import queryString from "query-string"

class ListFlow extends LoggedInPage {
  steps = {
    list: 1,
    item: 2,
    condition: 3,
    another_item: 4,
    schedule: 5,
    review: 6
  }

  constructor(props) {
    super(props)

    if (props.match.params.id) {
      this.state = {}
      List.find(this.props.currentUser, props.match.params.id)
      .then((list) => {
        console.log('[List.find]', list)
        this.setState({
          location_id: list.location_id,
          list: list,
          step: this.steps.review,
          is_template: props.location.search && queryString.parse(props.location.search).is_template
        })
      })
    } else {
      this.state = {
        location_id: props.match.params.location_id,
        list: null,
        step: this.steps.list,
        is_template: props.location.search && queryString.parse(props.location.search).is_template
      }
    }

    this.onListNamed = this.onListNamed.bind(this)
    this.onItemAdded = this.onItemAdded.bind(this)
    this.onConditionAdded = this.onConditionAdded.bind(this)
    this.onConditionSkipped = this.onConditionSkipped.bind(this)
    this.onItemPromptResponse = this.onItemPromptResponse.bind(this)
    this.onSchedule = this.onSchedule.bind(this)
    this.onSaveList = this.onSaveList.bind(this)
    this.onEditList = this.onEditList.bind(this)
    this.onAddItem = this.onAddItem.bind(this)
    this.onEditItem = this.onEditItem.bind(this)
    this.onEditCondition = this.onEditCondition.bind(this)
    this.onDeleteItem = this.onDeleteItem.bind(this)
    this.onDeleteCondition = this.onDeleteCondition.bind(this)
  }

  onListNamed(list) {
    this.setState({
      list,
      step: list.template_id ? (list.is_template ? this.steps.review : this.steps.schedule) : this.steps.item
    })
  }

  onItemAdded(item, skipped) {
    let list = this.state.list
    if (!list.items_attributes) {
      list.items_attributes = list.items || []
    }
    let step = this.steps.condition
    if (item != null) {
      if (item.id != null) {
        let existing = list.items_attributes.filter(i => i.id == item.id)[0]
        if (existing) {
          existing = {...item}
        } else {
          list.items_attributes.push(item)
        }
      } else {
        list.items_attributes.push(item)
      }
    } else {
      step = this.steps.another_item
    }
    console.log("Skipped =")
    console.log(skipped)
    let itemSkipped = null
    try {
      let itemSkipped = skipped
    } catch (exception){
      console.log('Exception!')
    }
    if (skipped) {
      console.log("ITEM skipped")
      step = this.steps.schedule
      item = null
    } else {
      if (item.id != null) {
        step = this.steps.review
        item = null
      }
    }
    this.setState({ list, item, step, condition: null })
  }

  onConditionAdded(condition, another) {
    let item = this.state.item
    if (!item.conditions_attributes) {
      item.conditions_attributes = item.conditions || []
    }
    if (condition.id != null) {
      let existing = item.conditions_attributes.filter(c => c.id == condition.id)[0]
      if (existing) {
        existing = {...condition}
      } else {
        item.conditions_attributes.push(condition)
      }
    } else {
      item.conditions_attributes.push(condition)
    }
    condition = null
    let step = another ? this.steps.condition : this.steps.another_item
    if (item.id != null && !another) {
      step = this.steps.review
      item = null
    }
    this.setState({
      item,
      condition,
      step: step
    })
  }

  onConditionSkipped() {
    this.setState({step: this.steps.another_item})
  }

  onItemPromptResponse(moreItems) {
    if (moreItems) {
      this.setState({ step: this.steps.item, item: null })
    } else {
      if (this.state.list.is_template) {
        this.setState({ step: this.steps.review })
      } else {
        this.setState({ step: this.steps.schedule })
      }
    }
  }

  onSchedule(schedule) {
    const list = this.state.list
    list.schedules_attributes = [schedule]
    this.setState({ list, step: this.steps.review })
  }

  onSaveList() {
    let listAction = null
    if (!this.state.list.id) {
      listAction = List.create(
        this.props.currentUser,
        this.state.location_id,
        this.state.list
      )
    } else {
      listAction = List.update(
        this.props.currentUser,
        this.state.list.id,
        this.state.list
      )
    }

    listAction
      .then((json) => {
        notify.show('List saved', 'success')

        if (this.state.location_id) {
          this.props.history && this.props.history.push(`/locations/${this.state.location_id}/lists`)
        } else {
          this.props.history && this.props.history.push('/dashboard')
        }
      })
      .catch((error) => {
        notify.show(error.message, 'error')
      })
  }

  onEditList() {
    this.setState({ step: this.steps.list })
  }

  onAddItem() {
    this.setState({ item: null, step: this.steps.item })
  }

  onEditItem(item) {
    this.setState({
      item: item,
      step: this.steps.item
    })
  }

  onEditCondition(item, condition) {
    this.setState({
      item: item,
      condition: condition,
      step: this.steps.condition
    })
  }

  onDeleteItem(item) {
    const { list } = this.state
    if (!list) {
      return
    }

    const existing = list.items_attributes.filter(i => i.id == item.id)[0]
    existing._destroy = true
  }

  onDeleteCondition(item, condition) {
    const { list } = this.state
    if (!list) {
      return
    }

    const existingItem = list.items_attributes.filter(i => i.id == item.id)[0]
    const existingCondition = existingItem.conditions_attributes.filter(c => c.id == condition.id)[0]
    existingCondition._destroy = true
  }

  renderListFields() {
    return (
      <ListForm list={this.state.list} onNext={this.onListNamed} currentUser={this.props.currentUser} is_template={this.state.is_template}/>
    )
  }

  renderItemFields() {
    return (
      <ItemForm onNext={this.onItemAdded} currentUser={this.props.currentUser} list={this.state.list} item={this.state.item} />
    )
  }

  renderConditionFields() {
    return (
      <ConditionForm onNext={this.onConditionAdded} onSkip={this.onConditionSkipped} currentUser={this.props.currentUser} list={this.state.list} item={this.state.item} condition={this.state.condition} />
    )
  }

  renderItemPrompt() {
    return (
      <ItemPrompt onItemPromptResponse={this.onItemPromptResponse} currentUser={this.props.currentUser} />
    )
  }

  renderSchedule() {
    return (
      <div className="row justify-content-center">
        <div className="col-11 col-xl-9">
          <section className="py-4 py-lg-5">
            <div className="row justify-content-center">
              <div className="col-11 col-md-11">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title text-center">Setup a schedule for this checklist</h4>
                    <form>
                      <ScheduleForm currentUser={this.props.currentUser} onSubmit={this.onSchedule} list={this.state.list} />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }

  renderReview() {
    return (
      <ListReview onSaveList={this.onSaveList} onEditList={this.onEditList} onAddItem={this.onAddItem} onEditItem={this.onEditItem} onEditCondition={this.onEditCondition} onDeleteItem={this.onDeleteItem} onDeleteCondition={this.onDeleteCondition} list={this.state.list} currentUser={this.props.currentUser} />
    )
  }

  render () {
    let fields = null
    switch(this.state.step) {
      case this.steps.list:
        fields = this.renderListFields()
        break
      case this.steps.item:
        fields = this.renderItemFields()
        break
      case this.steps.condition:
        fields = this.renderConditionFields()
        break
      case this.steps.another_item:
        fields = this.renderItemPrompt()
        break
      case this.steps.schedule:
        fields = this.renderSchedule()
        break
      case this.steps.review:
        fields = this.renderReview()
        break
    }
    return (
      <>
        {fields}
      </>
    );
  }
}

export default ListFlow
