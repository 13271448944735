import React from "react"
import LoggedInPage from "./LoggedInPage"
import LocationForm from "../components/LocationForm"
import Location from "../services/location"

class EditLocation extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      location: null,
      isLoading: true
    }

    Location.find(this.props.currentUser, props.match.params.id)
      .then((json) => {
        this.setState({ location: json, isLoading: false })
      }).catch((error) => {
        notify.show("Location not found", 'error')
        this.setState({ isLoading: false })
      })
  }

  render () {
    let location = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.location) {
        location = (
          <LocationForm {...this.props} location={this.state.location} />
        )
      } else {
        location = (
          <p>Unable to find that location.</p>
        )
      }
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">Edit Location</h4>
                      {location}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default EditLocation
