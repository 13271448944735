import React from "react"
import SignUp from "../components/SignUp"

export default (props) => {
  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <SignUp />
      </div>
    </div>
  )
}