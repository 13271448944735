import React from "react"

class LoggedInPage extends React.Component {
  constructor(props) {
    super(props)

    this.checkLoginStatus(props)
  }

  componentWillReceiveProps(props) {
    this.checkLoginStatus(props)
  }

  checkLoginStatus(props) {
    if (!props.isLoggedIn) {
      props.history && props.history.push('/')
    }
  }
}

export default LoggedInPage