import React from "react"
import { Link } from "react-router-dom"
import LoggedInPage from "./LoggedInPage"
import Employee from "../services/employee"
import Card from "../components/Card"
import PageHeader from "../components/PageHeader"

class Employees extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      employees: [],
      isLoading: true
    }

    Employee.all(props.currentUser)
      .then((json) => {
        this.setState({ employees: json, isLoading: false })
      })
      .catch((error) => {
        console.log("[Employee.all] error", error)
      })
  }

  render () {
    let employees = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.employees.length > 0) {
        employees = this.state.employees.map((employee) => {
           console.log(employee.email)
           console.log(employee.deactivated)
          return (
            <Card key={employee.id} name={employee.full_name ? employee.full_name : employee.email} url={`/employees/${employee.id}/edit`}>
            <br />
            {employee.role}<br />
            {employee.email}<br />
            <strong>{employee.deactivated ? "Deactivated" : ""}</strong></Card>
          )
        })
      } else {
        employees = (
          <p>No employees found.</p>
        )
      }
    }
    return (
      <div className="row justify-content-center">
        <div className="col-11 col-xl-9">
          <PageHeader title="Employees" currentUser={this.props.currentUser} />
          <div className="row">
            {employees}
          </div>
          <div className="row">
            { this.props.currentUser && this.props.currentUser.role.includes("admin","supervisor") && <Link to="/employees/new">Create a new employee</Link> }
          </div>
        </div>
      </div>
    );
  }
}

export default Employees
