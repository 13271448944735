import React from "react"
import { Link } from "react-router-dom"
import LoggedInPage from "./LoggedInPage"
import Location from "../services/location"
import PageHeader from "../components/PageHeader"
import Card from "../components/Card"
import ReactTable from 'react-table'
import "react-table/react-table.css"

class LocationScores extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      locations: [],
      isLoading: true
    }
    
    Location.all(props.currentUser)
      .then((json) => {
        this.setState({ locations: json, isLoading: false })
      }).catch((error) => {
        console.log("[Location.all] error", error)
      })
  }

  render() {
    // if (!this.state.isLoading) {
    //   if (this.state.locations.length > 0) {
    //     const locations = this.state.locations
    //   }
    // } else {
    //   let locations = []
    // }

    const { locations } = this.state;

    const columns = [{
      Header: 'Name',
      accessor: 'name' // String-based value accessors!
    }, {
      Header: 'City',
      accessor: 'city'
    }, {
      Header: 'State',
      accessor: 'state'
    }, {
      Header: 'Completion Grade',
      accessor: 'completion_grade'
    }, {      
      Header: 'On Time %',
      accessor: 'on_time_pct'
    }]
   
    return (
      <>
      <PageHeader title="Location Scores" currentUser={this.props.currentUser} />
      <div>
        <ReactTable
          data={locations}
          columns={columns}
          defaultSorted={[
            {
              id: "completion_grade",
              desc: true
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    </>
    );
  }
}

export default LocationScores



