import React from "react"
import { Link } from "react-router-dom"
import LoggedInPage from "./LoggedInPage"
import PageHeader from "../components/PageHeader"
import Card from "../components/Card"
import List from "../services/list"
import Location from "../services/location"
import { confirmAlert } from 'react-confirm-alert'

class Templates extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      lists: [],
      locations: [],
      isLoading: true
    }

    List.templates(props.currentUser)
      .then((json) => {
        this.setState({ lists: json, isLoading: false })
      })
      .catch((error) => {
        console.log("[List.templates] error", error)
      })

    Location.all(props.currentUser)
      .then((json) => {
        this.setState({ locations: json })
      }).catch((error) => {
        console.log("[Location.all] error", error)
      })

      this.confirmCreateLocation = this.confirmCreateLocation.bind(this);
  }

  confirmCreateLocation(event){
    event.preventDefault()
    const message = 'You currently have '+ this.props.currentUser.location_count +' locations. Do you want to add this location and update your pricing? (Note: you will be billed a prorated amount based on your original sign up date.)'

    confirmAlert({
      title: 'New Location?',
      message: message,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.history.push(`/locations/new`)
        },
        {
          label: 'No',
          onClick: () => this.props.history.push(`/locations`)
        }
      ]
    });
  };    

  render() {
    let lists = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.lists.length > 0) {
        lists = this.state.lists.map((list) => {
          return (
            <Card url={`/lists/${list.id}`} key={list.id} name={list.name} />
          )
        })
      } else {
        lists = (
          <p>No lists found.</p>
        )
      }
    }

    return (
      <div className="row justify-content-center">
        <div className="col-11 col-xl-9">
          <PageHeader title="Checklist Templates" currentUser={this.props.currentUser} />
          <div className="row">
            {this.state.locations.length > 0 && this.props.currentUser && this.props.currentUser.role === "admin" && 
              <Link to={`/locations/${this.state.locations[0]['id']}/lists/new?is_template=true`}>
                <button type="button" className="btn btn-primary btn-sm">
                  Create Template
                </button>
              </Link>
            }
            <hr />
          </div>
          <div className="row">
            {lists}
          </div>
          <div className="row">
            {this.state.locations.length === 0 && this.props.currentUser && this.props.currentUser.role === "admin" && <Link to="/locations/new" onClick={this.confirmCreateLocation}>Create a new location first</Link> }
          </div>
        </div>
      </div>
    )
  }
}

export default Templates