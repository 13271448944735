import React from "react"
import LoggedInPage from "./LoggedInPage"
import Card from "../components/Card"
import Logs from "../components/Logs"
import PageHeader from "../components/PageHeader"
import List from "../services/list"
import Log from "../services/log"
import { formatDateTime } from "../services/utils"
import { Redirect } from 'react-router-dom'
import { Link } from "react-router-dom"

class Dashboard extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      location_id: props.match.params.location_id,
      lists: {
        overdue: [],
        imminent: [],
        later: []
      },
      logs: [],
      isLoading: true,
      logsLoading: true
    }

    if (this.state.location_id) {
      Log.allForLocation(props.currentUser, this.state.location_id)
        .then((json) => {
          this.setState({ logs: json, logsLoading: false })
        })
        .catch((error) => {
          console.log("[Log.allForLocation] error", error)
        })
    }

    List.not_templates(props.currentUser, this.state.location_id)
      .then((json) => {
        const lists = {
          overdue: json.filter(list => list.overdue),
          imminent: json.filter(list => list.imminent),
          later: json.filter(list => !list.overdue && !list.imminent)
        }
        this.setState({ lists: lists, isLoading: false })
      })
      .catch((error) => {
        console.log("[List.all] error", error)
      })
  }

  renderLists(type, title) {
    let lists = (
      <p>No lists found.</p>
    )
    if (this.state.lists[type].length > 0) {
      lists = this.state.lists[type].map((list) => {
        let next_due_at = null
        if (list.next_due_at != null) {
          next_due_at = (
            <p>Next due: {formatDateTime(list.next_due_at)}</p>
          )
        }
        return (
          <Card url={`/lists/${list.id}`} key={list.id} name={list.name} >
            <p>{list.location_name}</p>
            {next_due_at}
          </Card>
        )
      })
    }
    return (
      <>
        <h5>{title}</h5>
        <div className="row">
          {lists}
        </div>
        <hr />
      </>
    )
  }

  render () {
    if (!this.props.isLoggedIn) {
      return null
    }
    if (this.props.currentUser.sign_in_count <= 10 && (this.props.currentUser.full_name === "" || this.props.currentUser.full_name === null)) { 
      return <Redirect to={`/employees/${this.props.currentUser.id}/edit`} />
    }
    let locations = null
    if (this.props.currentUser.role === "admin" && this.props.currentUser.location_count == 0) {
      locations = (
        <p>To get started, please create your first location. (If you need help, please watch our getting started video.)
        <Link to="/locations/new"> Create Location</Link>
        <hr /></p>
        ) 
    } 
    let templates = null
    if (this.props.currentUser.role === "admin" && this.props.currentUser.location_count > 0 && this.props.currentUser.template_count == 0) {
      templates = (
        <p>Next step, create your first template. (If you need help, please watch our getting started video.)
        <Link to="/templates"> Create Template</Link>
        <hr /></p>
        ) 
    }     
    let lists = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      lists = (
        <>
          {this.renderLists('overdue', 'Past Due')}
          {this.renderLists('imminent', 'Due in next hour')}
          {this.renderLists('later', 'Due later')}
        </>
      )
    }
    let logs = null
    if (this.state.location_id != null) {
      logs = (
        <>
          <h5>Completed Logs</h5>
          <div className="row">
            <Logs isLoading={this.state.logsLoading} logs={this.state.logs} />
          </div>
        </>
      )
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <PageHeader title={this.state.location_id ? 'Checklists for location' : 'Dashboard'} currentUser={this.props.currentUser} />
            {locations}
            {templates}
            {lists}
            {logs}
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard
