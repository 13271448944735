import React from "react"
import { notify } from "react-notify-toast"
import List from "../services/list"
import { Prompt } from "react-router"

class ListForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      isTemplate: props.is_template || false,
      always_report: props.always_report || false,
      template_id: null,
      ...props.list,
      lists: [],
      didChange: false
    }

    List.templates(props.currentUser)
      .then((json) => {
        this.setState({lists: json})
      })
      .catch((error) => {
        console.log("[List.templates] error", error)
      })

    this.onNameChanged = this.onNameChanged.bind(this)
    this.onIsTemplateChanged = this.onIsTemplateChanged.bind(this)
    this.onTemplateIdChanged = this.onTemplateIdChanged.bind(this)
    this.onAlwaysReportChanged = this.onAlwaysReportChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  validate() {
    return !(
      this.state.name == null ||
      this.state.name === ''
    )
  }

  onSubmit(event) {
    event.preventDefault()

    if (this.validate()) {
      let items = this.props.list ? this.props.list.items_attributes : []
      if (this.state.template_id) {
        if (this.state.id == null) { // Need to prevent template items from being added to existing lists
          const template = this.state.lists.filter(list => list.id === parseInt(this.state.template_id))[0]
          template.items.forEach((item) => {
            items.push({
              prompt: item.prompt,
              config: item.config,
              item_type: item.item_type,
              requires_photo: item.requires_photo,
              conditions_attributes: item.conditions.map((condition) => {
                return {
                  equality: condition.equality,
                  value: condition.value,
                  instructions: condition.instructions
                }
              })
            })
          })
        }
      }

      const list = this.props.list || {}
      list.name = this.state.name
      list.is_template = this.state.isTemplate
      list.template_id = this.state.template_id
      list.always_report = this.state.always_report
      list.items_attributes = list.items = items
      this.props.onNext(list)
    } else {
      notify.show('Must specify a checklist name!', 'error')
    }
  }

  onNameChanged(event) {
    this.setState({name: event.target.value})
    this.setState({didChange: true})
  }

  onIsTemplateChanged(event) {
    this.setState({isTemplate: event.target.checked})
    this.setState({didChange: true})
  }

  onTemplateIdChanged(event) {
    this.setState({template_id: event.target.value})
    this.setState({didChange: true})
  }

  onAlwaysReportChanged(event) {
    this.setState({always_report: event.target.checked})
    this.setState({didChange: true})
  }  

  render () {
    return (
      <>
        <Prompt
          when={this.state.didChange}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">{this.state.isTemplate ? "What is the name of this template?" : "What is the name of this checklist?"}</h4>
                      <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <input value={this.state.name} onChange={this.onNameChanged} type="text" className="form-control form-control-lg" id="name" aria-describedby="emailHelp" placeholder="Enter name" />
                        </div>
                        <div className="form-group">
                          <label htmlFor=""><h6>Use an existing list as a template</h6></label>
                          <select onChange={this.onTemplateIdChanged} className="form-control form-control-lg" id="template_id" value={this.state.template_id}>
                            <option>Choose one...</option>
                            {this.state.lists.map(list => <option key={list.id} value={list.id}>{list.name}</option>)}
                          </select>
                        </div>
                        <div className="form-check">
                          <input checked={this.state.always_report} onChange={this.onAlwaysReportChanged} className="form-check-input" type="checkbox" id="always_report" />
                          <label className="form-check-label" for="always_report">
                            Always Report Results?
                          </label>
                        </div>
                        <br />
                        <button type="submit" className="btn btn-primary btn-lg btn-block">Next</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default ListForm
