import React from "react"
import { notify } from "react-notify-toast"
import { Link } from "react-router-dom"
import LoggedInPage from "./LoggedInPage"
import Location from "../services/location"
import Avatar from "../components/Avatar"
import Card from "../components/Card"
import PageHeader from "../components/PageHeader"

class LocationView extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      location: null,
      isLoading: true
    }

    const locationId = props.match.params.id
    Location.find(this.props.currentUser, locationId)
      .then((json) => {
        this.setState({ location: json, isLoading: false })
      }).catch((error) => {
        notify.show("Location not found", 'error')
        this.setState({ isLoading: false })
      })
  }

  render () {
    let location = (
      <p>Loading...</p>
    )
    let links = null
    if (!this.state.isLoading) {
      if (this.state.location) {
        let subscribers = null
        if (this.state.location.subscribers && this.state.location.subscribers.length > 0) {
          subscribers = (
            <>
              <br />
              <p><strong>Who gets notified?</strong></p>
                {this.state.location.subscribers.map(subscriber => <div>{subscriber.full_name ? subscriber.full_name : subscriber.email}</div>)}
            </>
          )
        }
        let accessors = null
        if (this.state.location.accessors && this.state.location.accessors.length > 0) {
          accessors = (
            <>
              <br />
              <p><strong>Who can complete checklists?</strong></p>
                {this.state.location.accessors.map(accessor => <div>{accessor.full_name ? accessor.full_name : accessor.email}</div>)}
              <br />  
            </>
          )
        }
        location = (
          <Card key={this.state.location.id} name={this.state.location.name} large={true}>
            <p>
              {this.state.location.street}
              <br />
              {this.state.location.city}
              <br />
              {this.state.location.state}, {this.state.location.zip}
            </p>
            {subscribers}
            {accessors}
            <Link to={`/locations/${this.state.location.id}/edit`}>Edit</Link>
          </Card>
        )
        links = (
          <>
            <Link to="/locations">View locations</Link>&nbsp;|&nbsp;
            <Link to={`/locations/${this.state.location.id}/completions`}>View completion breakdown</Link>&nbsp;|&nbsp;
            <Link to={`/locations/${this.state.location.id}/lists`}>Checklists for location</Link>&nbsp;|&nbsp;
            <Link to={`/locations/${this.state.location.id}/lists/new`}>Create checklist for location</Link>
          </>
        )
      } else {
        location = (
          <p>Unable to find location.</p>
        )
      }
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <PageHeader title="Location" currentUser={this.props.currentUser} />
            <div className="row">
              {location}
            </div>
            <div className="row">
              {links}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LocationView
