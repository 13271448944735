import React from "react"
import ListView from "./ListView"
import { formatDateTime } from "../services/utils"

class ListReview extends React.Component {
  constructor(props) {
    super(props)

    this.onSave = this.onSave.bind(this)
    this.onEdit = this.onEdit.bind(this)
    this.onAddItem = this.onAddItem.bind(this)
    this.onEditItem = this.onEditItem.bind(this)
    this.onEditCondition = this.onEditCondition.bind(this)
  }

  onSave(event) {
    event.preventDefault()

    this.props.onSaveList()
  }

  onEdit(event) {
    event.preventDefault()

    this.props.onEditList()
  }

  onAddItem() {
    this.props.onAddItem()
  }

  onEditItem(item) {
    this.props.onEditItem(item)
  }

  onEditCondition(item, condition) {
    this.props.onEditCondition(item, condition)
  }

  renderSchedule() {
    const schedule = this.props.list.schedules_attributes && this.props.list.schedules_attributes[0]
    if (schedule) {
      let days = null
      if (schedule.schedule_type === 'daily') {
        days = (
          <ul>
            {schedule.days.split(',').map(day => <li>{day}</li>)}
          </ul>
        )
      }
      return (
        <>
          <p>
            Scheduled {schedule.schedule_type}, starting at {formatDateTime(schedule.starts_at)}
          </p>
          {days}
        </>
      )
    }
  }

  render () {
    // display checklist
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">{this.props.list.is_template ? "Review your template." : "Review your checklist."}</h4>
                      <form>
                        <ListView list={this.props.list} readonly={true} onEditItem={this.onEditItem} onEditCondition={this.onEditCondition} onDeleteItem={this.props.onDeleteItem} onDeleteCondition={this.props.onDeleteCondition} />
                        {this.renderSchedule()}
                        <button onClick={this.onSave} className="btn btn-primary btn-lg btn-block">Save</button>
                        {this.props.list.id || this.props.list.template_id ? <button onClick={this.onEdit} className="btn btn-light btn-lg btn-block">Edit</button> : null}
                        <button onClick={this.onAddItem} className="btn btn-light btn-lg btn-block">Add Item</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default ListReview
