import API from './api'

const Auth = {
  signIn: function(email, password) {
    return API.http(null, 'auth/sign_in', 'POST', {
      email: email,
      password: password
    }, 'application/json')
  },

  signUp: function(data) {
    return API.post(null, 'auth', data)
  },

  signOut: function(user) {
    return API.delete(null, 'auth/sign_out')
  },

  requestTemporaryPassword: function(email) {
    return API.post(null, 'passwords', {password: { email }})
  }
}

export default Auth