import React from "react"
import { notify } from "react-notify-toast"
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from "react-stripe-elements"
import Pricing from "../components/Pricing"
import PageHeader from "../components/PageHeader"

class StripeBilling extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      discountCode: props.discountCode,
      planCode: props.planCode,
      myEnv: process.env.NODE_ENV,
      options: []
    }

    this.onDiscountCodeChanged = this.onDiscountCodeChanged.bind(this)
    this.onPlanCodeChanged = this.onPlanCodeChanged.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    console.log('process.env.NODE_ENV: ' + process.env.NODE_ENV)
    if (this.state.myEnv != 'production') {
      this.state.options = [
        { label: "Monthly", value: "price_1JpYWCCqcX1dWQDru0s5zrDh", className: 'planCode'},
        { label: "Annual", value: "price_1JsWTCCqcX1dWQDrbfJCbdgh", className: 'planCode'}
      ];
    } else {
      this.state.options = [      
          { label: "Monthly", value: "price_1K1B6ECqcX1dWQDryxGVkD6f", className: 'planCode'},
          { label: "Annual", value: "price_1K1B6ECqcX1dWQDrYo2mVMpn", className: 'planCode'}  
      ];
    }

  }

  onDiscountCodeChanged(event) {
    this.setState({ discountCode: event.target.value })
    this.props.onDiscountCodeChanged && this.props.onDiscountCodeChanged(event)
  }

  onPlanCodeChanged(event) {
    this.setState({ planCode: event.value});
    this.props.onPlanCodeChanged && this.props.onPlanCodeChanged(event)
  }  

  handleSubmit(event) {
    event.preventDefault()

    this.props.stripe.createToken({type: 'card'}).then((result) => {
      if (result.error) {
        notify.show(result.error.message, 'error')
      } else {
        this.props.handleStripeToken && this.props.handleStripeToken(result.token.id)
      }
    })
  }

  render() {
    const inputStyle = {
      base: {
        border: '1px solid #E9EEF2',
        borderRadius: '0.3rem',
        color: '#495057',
        display: 'block',
        fontSize: '.875rem',
        fontWeight: '500',
        lineHeight: '1.5',
        width: '100%'
      }
    }

    let planCodeDisplay = null
    if (typeof(this.state.planCode) === 'undefined' && this.state.planCode == null) { 
      planCodeDisplay = (
        <Dropdown options={this.state.options} value={this.state.planCode} onChange={this.onPlanCodeChanged} placeholder="Select a billing option" id="planCode" />
      )
    }

    return (
      <div className="card">
        <div className="card-body">
        <Pricing />
          <h4 className="text-center">Billing Info</h4>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              {planCodeDisplay}
            </div>
            <div className="form-group">
              <CardNumberElement style={inputStyle} />
            </div>
            <div className="form-group">
              <CardExpiryElement style={inputStyle} />
            </div>
            <div className="form-group">
              <CardCVCElement style={inputStyle} />
            </div>
            <div className="form-group">
              <input value={this.state.discountCode} onChange={this.onDiscountCodeChanged} type="text" className="form-control form-control-lg" id="discountCode" aria-describedby="emailHelp" placeholder="Discount Code (if you have one)" />
            </div>
            <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={this.handleSubmit}>{this.props.submitText || 'Complete sign up'}</button>
          </form>
          <br />
        </div>
      </div>
    )
  }
}

export default injectStripe(StripeBilling)
