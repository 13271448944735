import React from "react"
import { Link } from "react-router-dom"
import { formatDateTime } from "../services/utils"

class Logs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      logs: props.logs,
      isLoading: props.isLoading
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ logs: props.logs, isLoading: props.isLoading })
  }

  render () {
    let logs = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.logs && this.state.logs.length > 0) {
        logs = this.state.logs.map(log => (
          <tr>
            <td>{log.list_name}</td>
            <td>{log.user.full_name}</td>
            <td>{formatDateTime(log.created_at)}</td>
            <td>
              <Link to={`/lists/${log.list_id}/logs/${log.id}`}>View</Link>
            </td>
          </tr>
        ))
      } else {
        logs = (
          <p>Unable to find any logs for this checklist.</p>
        )
      }
    }
    return (
      <>
        <table className="logs">
          <tr>
            <th>Checklist</th>
            <th>User</th>
            <th>Logged at</th>
            <th>&nbsp;</th>
          </tr>
          {logs}
        </table>
      </>
    );
  }
}

export default Logs
