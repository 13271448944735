import React from "react"
import { Link } from "react-router-dom"
import LoggedInPage from "./LoggedInPage"
import Location from "../services/location"
import PageHeader from "../components/PageHeader"
import Card from "../components/Card"
import { confirmAlert } from 'react-confirm-alert'
//import 'react-confirm-alert/src/react-confirm-alert.css'

class Locations extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      locations: [],
      isLoading: true
    }

    Location.all(props.currentUser)
      .then((json) => {
        this.setState({ locations: json, isLoading: false })
      }).catch((error) => {
        console.log("[Location.all] error", error)
      })

      this.confirmCreateLocation = this.confirmCreateLocation.bind(this);
  }

  confirmCreateLocation(event){
    event.preventDefault()
    const message = 'You currently have '+ this.props.currentUser.location_count +' locations. Do you want to add this location and update your pricing? (Note: you will be billed a prorated amount based on your original sign up date.)'
    confirmAlert({
      title: 'New Location?',
      message: message,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.history.push(`/locations/new`)
        },
        {
          label: 'No',
          onClick: () => this.props.history.push(`/locations`)
        }
      ]
    });
  };    

  render () {
    let locations = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.locations.length > 0) {
        locations = this.state.locations.map((location) => {
          return (
            <Card key={location.id} name={location.name} url={`/locations/${location.id}`} avatars={location.users} street={`${location.street}`} citystatezip={`${location.city}, ${location.state}, ${location.zip}`} score1={location.completion_grade} score2={location.on_time_pct} />
          )
        })
      } else {
        locations = (
          <p>No locations found.</p>
        )
      }
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <PageHeader title="Locations" currentUser={this.props.currentUser} />
            <div className="row">
              {this.state.locations.length > 0 && this.props.currentUser && this.props.currentUser.role === "admin" && 
                <Link to="/locationscores">
                  <button type="button" className="btn btn-primary btn-sm">
                    View Scoreboard
                  </button>
                </Link>
              }
              {this.state.locations.length > 0 && this.props.currentUser && this.props.currentUser.role === "admin" && 
                <Link to="/locations/new">
                  <button type="button" className="btn btn-primary btn-sm" style={{'margin-left': '20px'}} onClick={this.confirmCreateLocation}>
                    Create Location
                  </button>
                </Link>
              }
              <hr />
            </div>
            <div className="row">
              {locations}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Locations
