import React from "react"
import Pricing from "../components/Pricing"

export default (props) => {
  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <Pricing />
      </div>
    </div>
  )
}