import React from "react"
import { notify } from "react-notify-toast"
import LoggedInPage from "./LoggedInPage"
import ScheduleForm from "../components/ScheduleForm"
import List from "../services/list"
import Schedule from "../services/schedule"

class NewSchedule extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      lists: [],
      list_id: ''
    }

    this.onListIdChanged = this.onListIdChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    List.all(props.currentUser)
      .then((json) => {
        this.setState({lists: json})
      })
      .catch((error) => {
        console.log("[List.all] error", error)
      })
  }

  onSubmit(schedule) {
    event.preventDefault()

    Schedule.create(
      this.props.currentUser,
      this.state.list_id,
      schedule.starts_at,
      schedule.ends_at,
      schedule.schedule_type,
      schedule.days)
    .then((json) => {
      this.setState({list_id: ''})
      notify.show('Schedule saved', 'success')
      this.props.history && this.props.history.push('/schedules')
    })
    .catch((error) => {
      notify.show(error.message, 'error')
    })
  }

  onListIdChanged(event) {
    this.setState({list_id: event.target.value})
  }

  render () {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">New Schedule</h4>
                      <form>
                        <div className="form-group">
                          <select name="list_id" className="form-control form-control-lg" onChange={this.onListIdChanged} value={this.state.list_id}>
                            <option>Choose a list...</option>
                            {this.state.lists.map(list => <option value={list.id}>{list.name}</option>)}
                          </select>
                        </div>
                        <ScheduleForm onSubmit={this.onSubmit} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default NewSchedule
