import React from "react"
import LoggedInPage from "./LoggedInPage"
import LocationForm from "../components/LocationForm"
import { Link } from "react-router-dom"

class NewLocation extends LoggedInPage {
  render () {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="row justify-content-center">
                    <Link to="/templates" style={{margin: "0.8rem"}}>Create Checklist Template</Link><Link to="/home" style={{margin: "0.8rem"}}> Return to Dashboard</Link>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">New Location</h4>
                      <LocationForm currentUser={this.props.currentUser} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default NewLocation
