import React from "react"
import { notify } from "react-notify-toast"
import { Link } from "react-router-dom"
import LoggedInPage from "./LoggedInPage"
import Avatar from "../components/Avatar"
import Card from "../components/Card"
import PageHeader from "../components/PageHeader"
import ReactPlayer from 'react-player'

class GettingStarted extends LoggedInPage {
  constructor(props) {
    super(props)

  }

  render () {
    return (
            <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="mb-3 d-flex">
                <img alt="Linecheck" src="/assets/img/linecheck.png" height="50" style={{'marginTop': '10px'}} />
              </div>
              <h1 className="display-4 mb-3">Getting Started</h1>
              <p className="lead">
                You’re only a few steps away from better team accountability.
              </p>
              <hr />
              <div className='player-wrapper'>
                <ReactPlayer 
                  className='react-player'
                  url={[{src: '/assets/videos/Setting_Up_LineCheck.mp4', type: 'video/mp4'}]}
                  width='60%'
                  height='60%'
                  controls='true'
                />
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }
}

export default GettingStarted
