import React from "react"

class CompanyFields extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      ...props.company
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(event) {
    this.setState({ [event.target.id]: event.target.value })
    this.props.onChange && this.props.onChange(event)
  }

  render() {
    return (
      <>
        <div className="form-group">
          <input value={this.state.name} onChange={this.onChange} type="text" className="form-control form-control-lg" id="name" aria-describedby="emailHelp" placeholder="Company Name" />
        </div>
        <div className="form-group">
          <input value={this.state.street} onChange={this.onChange} type="text" className="form-control form-control-lg" id="street" aria-describedby="emailHelp" placeholder="Street" />
        </div>
        <div className="form-group">
          <input value={this.state.city} onChange={this.onChange} type="text" className="form-control form-control-lg" id="city" aria-describedby="emailHelp" placeholder="City" />
        </div>
        <div className="form-group">
          <input value={this.state.state} onChange={this.onChange} type="text" className="form-control form-control-lg" id="state" aria-describedby="emailHelp" placeholder="State" />
        </div>
        <div className="form-group">
          <input value={this.state.zip} onChange={this.onChange} type="text" className="form-control form-control-lg" id="zip" aria-describedby="emailHelp" placeholder="Zip" />
        </div>
        <div className="form-group">
          <input value={this.state.phone} onChange={this.onChange} type="text" className="form-control form-control-lg" id="phone" aria-describedby="emailHelp" placeholder="Phone" />
        </div>        
      </>
    )
  }
}

export default CompanyFields