import React from "react"
import { Link } from "react-router-dom"
import Avatar from "./Avatar"

class Card extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    let detail = null
    if (this.props.detail) {
      detail = (
        <p>{this.props.detail}</p>
      )
    }
    let street = null
    if (this.props.street) {
      street = (
        <div>{this.props.street}</div>
      )
    }
    let citystatezip = null
    if (this.props.citystatezip) {
      citystatezip = (
        <p>{this.props.citystatezip}</p>
      )
    }       
    let score1 = null
    if (this.props.score1) {
      score1 = (
        <p>Completion: {this.props.score1}%</p>
      )
    }
    let score2 = null
    if (this.props.score2) {
      score2 = (
        <p>On Time: {this.props.score2}%</p>
      )
    }

    let col_xl = 4, col = 6
    if (this.props.large) {
      col_xl = 8
      col = 12
    }
    return (
      <div className={`col-xl-${col_xl} col-${col}`}>
        <div className="card mb-3">
          <div className="card-body">
            <Link to={this.props.url} className="card-title h6">{this.props.name}</Link>
            {detail}
            {street}
            {citystatezip}
            {score1}
            {score2}
            {this.props.children}
            {this.props.avatars && this.props.avatars.map(user => <div>{user.full_name ? user.full_name : user.email}</div>)}
          </div>           
        </div>
      </div>
    )
  }
}

export default Card
