import React from "react"
import { notify } from "react-notify-toast"
import LoggedInPage from "./LoggedInPage"
import Employee from "../services/employee"
import UserForm from "../components/UserForm"

class NewEmployee extends LoggedInPage {
  constructor(props) {
    super(props)

    this.onSave = this.onSave.bind(this)
  }

  onSave(email, full_name, role) {
    Employee.create(
      this.props.currentUser,
      email,
      full_name,
      role
    )
    .then((json) => {
      notify.show('Employee saved', 'success')
      this.props.history && this.props.history.push('/employees')
    })
    .catch((error) => {
      notify.show(error.message, 'error')
    })
  }

  render () {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">Employee Info</h4>
                      <UserForm currentUser={this.props.currentUser} onSave={this.onSave} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default NewEmployee
