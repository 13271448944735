import React from "react"
import { Link } from "react-router-dom"
import { notify } from "react-notify-toast"
import LoggedInPage from "./LoggedInPage"
import List from "../services/list"
import ListView from "../components/ListView"
import Log from "../services/log"
import PageHeader from "../components/PageHeader"
import ListAdminLinks from "../components/ListAdminLinks"

class ListPage extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      list: null,
      isLoading: true,
      isSaving: false,
      responses: {},
      evidences: {}
    }

    this.onResponsesUpdated = this.onResponsesUpdated.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onDelete = this.onDelete.bind(this)

    const listId = props.match.params.id
    List.find(this.props.currentUser, listId)
      .then((json) => {
        this.setState({ list: json, isLoading: false })
      }).catch((error) => {
        notify.show("List not found", 'error')
        this.setState({ isLoading: false })
      })
  }

  onResponsesUpdated(responses, evidences) {
    this.setState({ responses, evidences })
  }

  validate() {
    let valid = true
    this.state.list.items.forEach((item) => {
      if (this.state.responses[item.id] == null) {
        valid = false
      }
      if (item.requires_photo && this.state.evidences[item.id] == null) {
        valid = false
      }
    })
    return valid
  }

  onDelete(event) {
    event.preventDefault()
    const listType =  this.state.list.is_template ? "template" : "checklist"
    if (confirm("Are you sure you wish to delete this " + listType +"?")) {
      List.delete(this.props.currentUser, this.state.list.id)
      this.props.history && this.props.history.push(`/locations/${this.state.list.location_id}`)
    }
  }

  onSubmit(event) {
    event.preventDefault()

    if (this.state.isSaving) {
      return
    } else {
      this.setState({ isSaving: true })
    }

    if (this.validate()) {
      Log.create(
        this.props.currentUser,
        this.state.list.id,
        this.state.responses
      ).then((json) => {
        const log = json
        const evidenceUploads = []
        Object.keys(this.state.evidences).forEach((item_id) => {
          evidenceUploads.push(Log.uploadEvidence(
            this.props.currentUser,
            log,
            item_id,
            this.state.evidences[item_id]
          ))
        })

        Promise
        .all(evidenceUploads)
        .then(() => {
          this.setState({ responses: {}, evidences: {}, isSaving: false })
          notify.show('Checklist responses saved', 'success')

          this.props.history && this.props.history.push(`/lists/${this.state.list.id}/logs/${json.id}`)
        })
      })
      .catch((error) => {
        notify.show(error.message, 'error')
        this.setState({ isSaving: false })
      })
    } else {
      this.setState({ isSaving: false })
      notify.show('Please respond to each item, including photo evidence where required', 'error')
    }
  }

  render () {
    let list = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.list) {
        list = (
          <>
            {this.state.list.is_template ? null : <Link to={`/lists/${this.state.list.id}/logs`}>View logs</Link>}
            <br />
            {this.props.currentUser && this.props.currentUser.role === "admin" && <Link to={`/lists/${this.state.list.id}/edit`}>Edit</Link>  }
            <br />
            {this.props.currentUser && this.props.currentUser.role === "admin" && <Link to={`/locations/${this.state.list.location_id}`} onClick={this.onDelete}>Delete </Link> }
            <hr />
            <form onSubmit={this.onSubmit}>
              <ListView list={this.state.list} onResponsesUpdated={this.onResponsesUpdated} readonly={this.state.list.is_template} />
              {this.state.list.is_template ? null : <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={this.state.isSaving}>Log</button>}
            </form>
          </>
        )
      } else {
        list = (
          <p>Unable to find checklist.</p>
        )
      }
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <PageHeader title={this.state.list ? this.state.list.name : "Loading..."} currentUser={this.props.currentUser} />
            {list}
          </div>
        </div>
      </>
    );
  }
}

export default ListPage
