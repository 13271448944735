import API from './api'

const Location = {
  all: function(user) {
    return API.get(user, 'locations')
  },

  find: function(user, id) {
    return API.get(user, `locations/${id}`)
  },

  create: function(user, name, street, city, state, zip, subscriber_ids, accessor_ids) {
    return API.post(user, 'locations', { location: { name, street, city, state, zip, subscriber_ids, accessor_ids } })
  },

  update: function(user, location, name, street, city, state, zip, subscriber_ids, accessor_ids) {
    return API.put(user, `locations/${location.id}`, { location: { name, street, city, state, zip, subscriber_ids, accessor_ids } })
  }
}

export default Location