import React from "react"
import { Link } from "react-router-dom"
import LoggedInPage from "./LoggedInPage"
import Schedule from "../services/schedule"
import PageHeader from "../components/PageHeader"
import Card from "../components/Card"

class Schedules extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      schedules: [],
      isLoading: true
    }

    Schedule.all(props.currentUser)
      .then((json) => {
        this.setState({ schedules: json, isLoading: false })
      }).catch((error) => {
        console.log("[Schedule.all] error", error)
      })
  }

  render () {
    let schedules = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.state.schedules.length > 0) {
        schedules = this.state.schedules.map((schedule) => {
          return (
            <Card key={schedule.id} name={`${schedule.starts_at}; type: ${schedule.schedule_type}; days: ${schedule.days}`} url={`/schedules/${schedule.id}`} avatars={[schedule.created_by]} />
          )
        })
      } else {
        schedules = (
          <p>No schedules found.</p>
        )
      }
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <PageHeader title="Schedules" currentUser={this.props.currentUser} />
            <div class="row">
              {schedules}
            </div>
            <div class="row">
              <Link to="/schedules/new">Create a new schedule</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Schedules
