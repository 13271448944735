import React from "react"

export default (props) => {
  return (
    <>
      <section className="py-4 py-lg-5">
        <div className="mb-3 d-flex">
          <img alt="Linecheck" src="/assets/img/linecheck.png" height="50" style={{'marginTop': '10px'}} />
        </div>
        <h1 className="display-4 mb-3">{props.title}</h1>
      </section>
      <hr />
    </>
  )
}