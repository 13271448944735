import React from "react"
import PageHeader from "../components/PageHeader"

class ErrorPage extends React.Component {
  constructor(props) {
    super(props)

    if (props.bugsnagClient) {
      this.bugsnagErrors = props.bugsnagClient.getPlugin('react')
    }

    this.state = {hasError: false, error: null, info: null}
  }

  componentDidCatch(error, info) {
    if (this.bugsnagErrors) {
      this.bugsnagErrors.componentDidCatch(error, info)
    }
    this.setState({hasError: true, error: error, info: info})
  }

  render() {
    if (this.state.hasError) {
      let stacktrace = null
      if (this.props.env != 'production') {
        stacktrace = (
          <code>
            {this.state.info.componentStack}
          </code>
        )
      }
      return (
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <PageHeader title="Error" />
            <p>
              There has been an error: <strong>{this.state.error.message}</strong>
            </p>
            {stacktrace}
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorPage