import moment from 'moment';

export const formatDateTime = function(datetime) {
  return moment(datetime).format('MM/DD/YYYY h:mm a')
}

export const daysAgo = function(datetime, days) {
  return moment(datetime).subtract(days, 'days');
}

export const formatDateForRange = function(datetime) {
  return moment(datetime).format('YYYY-MM-DD');
}

export const formatDateFromUNIX = function(timestamp, return_type) {
  var a = new Date(timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  var date_only = month + ' ' + date + ' ' + year
  return eval(return_type);
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}