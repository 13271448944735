import API from './api'

const List = {
  list_url: function(location_id) {
    if (location_id) {
      return `locations/${location_id}/lists`
    } else {
      return 'lists'
    }
  },

  all: function(user, location_id) {
    return API.get(user, this.list_url(location_id))
  },

  templates: function(user, location_id) {
    return API.get(user, `${this.list_url(location_id)}?templates=true`)
  },

  not_templates: function(user, location_id) {
    return API.get(user, `${this.list_url(location_id)}?templates=false`)
  },

  find: function(user, id) {
    return API.get(user, `lists/${id}`)
  },

  create: function(user, location_id, list) {
    list = this.setOrderForItems(list)
    return API.post(user, this.list_url(location_id), { list })
  },

  update: function(user, list_id, list) {
    list = this.setOrderForItems(list)
    return API.put(user, `lists/${list_id}`, { list })
  },

  delete: function(user, list_id) {
    return API.delete(user, `lists/${list_id}`)
  },

  setOrderForItems(list) {
    if (list.items_attributes) {
      list.items_attributes.forEach((item, index) => {
        item.order = index + 1
      })
    }
    return list
  }
}

export default List