import React from "react"
import { Link } from "react-router-dom"
import { notify } from "react-notify-toast"
import Location from "../services/location"
import Employee from "../services/employee"
import MediaQuery from 'react-responsive'

class LocationForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      subscriber_ids: (props.location && props.location.subscribers && props.location.subscribers.map(s => s.id)) || [],
      subscriber_email: '',
      accessor_ids: (props.location && props.location.accessors && props.location.accessors.map(a => a.id)) || [],
      accessor_email: '',
      employees: [],
      location: props.location,
      ...props.location
    }

    this.onNameChanged = this.onNameChanged.bind(this)
    this.onStreetChanged = this.onStreetChanged.bind(this)
    this.onCityChanged = this.onCityChanged.bind(this)
    this.onStateChanged = this.onStateChanged.bind(this)
    this.onZipChanged = this.onZipChanged.bind(this)
    this.onSubscriberIdsChanged = this.onSubscriberIdsChanged.bind(this)
    this.onSubscriberEmailChanged = this.onSubscriberEmailChanged.bind(this)
    this.onAccessorIdsChanged = this.onAccessorIdsChanged.bind(this)
    this.onAccessorEmailChanged = this.onAccessorEmailChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.addSubscriber = this.addSubscriber.bind(this)
    this.addAccessor = this.addAccessor.bind(this)

    Employee.all(props.currentUser)
      .then((json) => {
        this.setState({employees: json})
      })
      .catch((error) => {
        console.log("[Employee.all] error", error)
      })
  }

  addSubscriber() {
    this.addEmployee('subscriber')
  }

  addAccessor() {
    this.addEmployee('accessor')
  }

  addEmployee(selector) {
    Employee.create(this.props.currentUser, this.state[`${selector}_email`], null, 'employee')
    .then((json) => {
      const employees = this.state.employees
      employees.push(json)
      const selection_id = `${selector}_ids`;
      const selection = this.state[selection_id]
      selection.push(json.id)
      this.setState({
        employees,
        [selection_id]: selection,
        [`${selector}_email`]: ''
      })
    })
  }

  onSubmit(event) {
    event.preventDefault()

    let updates = []
    if (this.state.subscriber_email != null && this.state.subscriber_email !== '') {
      updates.push(
        Employee.create(this.props.currentUser, this.state.subscriber_email, null, 'employee')
          .then((json) => {
            notify.show('Employee created! A confirmation email has been sent to them.', 'success')
            this.setState({subscriber_id: json.id})
          }
        )
      )
    }

    if (this.state.accessor_email != null && this.state.accessor_email !== '') {
      updates.push(
        Employee.create(this.props.currentUser, this.state.accessor_email, null, 'employee')
          .then((json) => {
            notify.show('Employee created! A confirmation email has been sent to them.', 'success')
            this.setState({accessor_id: json.id})
          }
        )  
      )
    }

    Promise
      .all(updates)
      .then(() => {
        let location_save = null
        if (this.state.location && this.state.location.id) {
          location_save = Location.update(
            this.props.currentUser,
            this.state.location,
            this.state.name,
            this.state.street,
            this.state.city,
            this.state.state,
            this.state.zip,
            this.state.subscriber_ids,
            this.state.accessor_ids)
        } else {
          location_save = Location.create(
            this.props.currentUser,
            this.state.name,
            this.state.street,
            this.state.city,
            this.state.state,
            this.state.zip,
            this.state.subscriber_ids,
            this.state.accessor_ids)
        }
        location_save.then((json) => {
          this.setState({name: '', street: '', city: '', state: '', zip: '', subscriber_ids: [], subscriber_email: '', accessor_ids: [], accessor_email: ''})
          notify.show('Location saved', 'success')
          this.props.history && this.props.history.push('/locations')
        })
        .catch((error) => {
          notify.show(error.message, 'error')
        })
      })
      .catch((error) => {
        notify.show(error.message, 'error')
      })
  }

  onNameChanged(event) {
    this.setState({name: event.target.value})
  }

  onStreetChanged(event) {
    this.setState({street: event.target.value})
  }

  onCityChanged(event) {
    this.setState({city: event.target.value})
  }

  onStateChanged(event) {
    this.setState({state: event.target.value})
  }

  onZipChanged(event) {
    this.setState({zip: event.target.value})
  }

  onSubscriberIdsChanged(event) {
    this.setState({ subscriber_ids: [...event.target.selectedOptions].map(o => o.value) })
  }

  onSubscriberEmailChanged(event) {
    this.setState({subscriber_email: event.target.value})
  }

  onAccessorIdsChanged(event) {
    this.setState({ accessor_ids: [...event.target.selectedOptions].map(o => o.value) })
  }

  onAccessorEmailChanged(event) {
    this.setState({accessor_email: event.target.value})
  }

  render () {
    return (
      <>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <input value={this.state.name} onChange={this.onNameChanged} type="text" className="form-control form-control-lg" id="name" aria-describedby="emailHelp" placeholder="Location Name or Number" />
          </div>
          <div className="form-group">
            <input value={this.state.street} onChange={this.onStreetChanged} type="text" className="form-control form-control-lg" id="street" aria-describedby="emailHelp" placeholder="Street" />
          </div>
          <div className="form-group">
            <input value={this.state.city} onChange={this.onCityChanged} type="text" className="form-control form-control-lg" id="city" aria-describedby="emailHelp" placeholder="City" />
          </div>
          <div className="form-group">
            <input value={this.state.state} onChange={this.onStateChanged} type="text" className="form-control form-control-lg" id="state" aria-describedby="emailHelp" placeholder="State" />
          </div>
          <div className="form-group">
            <input value={this.state.zip} onChange={this.onZipChanged} type="text" className="form-control form-control-lg" id="zip" aria-describedby="emailHelp" placeholder="Zip" />
          </div>
          <div className="form-group">
            <label for="" title="Users in this list will receive email notifications of missed checklists and punchlists for this location."><h6>Who gets notified?</h6></label>
            <select value={this.state.subscriber_ids} onChange={this.onSubscriberIdsChanged} className="form-control form-control-lg" id="subscriber_ids" multiple={true}>
              {this.state.employees.filter((employee) => employee.deactivated === false).map(employee => <option key={employee.id} value={employee.id}>{employee.full_name ? employee.full_name : employee.email}</option>)}
            </select>
            <MediaQuery minDeviceWidth={1224}>
              <p><small>Hold ctrl or cmd to select multiple</small></p>
            </MediaQuery>
          </div>
          <div className="form-group">
            <label for="">Enter email to add new employee</label>
            <input value={this.state.subscriber_email} onChange={this.onSubscriberEmailChanged} type="email" className="form-control form-control-lg employee_email_control" id="subscriber_email" aria-describedby="emailHelp" placeholder="Email address" />
            <Link className="employee_email_add" onClick={this.addSubscriber}>Add</Link>
          </div>
          <div className="form-group">
            <label for="" title="Users in this list are able to edit the checklists assigned to this location."><h6>Who can complete checklists?</h6></label>
            <select value={this.state.accessor_ids} onChange={this.onAccessorIdsChanged} className="form-control form-control-lg" id="accessor_ids" multiple={true}>
              {this.state.employees.filter((employee) => employee.deactivated === false).map(employee => <option key={employee.id} value={employee.id}>{employee.full_name ? employee.full_name : employee.email}</option>)}
            </select>
            <MediaQuery minDeviceWidth={1224}>
              <p><small>Hold ctrl or cmd to select multiple</small></p>
            </MediaQuery>
          </div>
          <div className="form-group">
            <label for="">Enter email to add new employee</label>
            <input value={this.state.accessor_email} onChange={this.onAccessorEmailChanged} type="email" className="form-control form-control-lg employee_email_control" id="accessor_email" aria-describedby="emailHelp" placeholder="Email address" />
            <Link className="employee_email_add" onClick={this.addAccessor}>Add</Link>
          </div>
          <button type="submit" className="btn btn-primary btn-lg btn-block">Save</button>
        </form>
      </>
    );
  }
}

export default LocationForm
