import React from "react"
import { Link } from "react-router-dom"
import { notify } from "react-notify-toast"
import { Elements } from "react-stripe-elements"
import StripeBilling from "./StripeBilling"
import CompanyFields from "../components/CompanyFields"
import Auth from "../services/auth"

class SignUp extends React.Component {
  steps = {
    user: 1,
    company: 2,
    billing: 3,
    complete: 4
  }

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      fullName: '',
      step: this.steps.user
    }

    this.onContinue = this.onContinue.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onEmailChanged = this.onEmailChanged.bind(this)
    this.onPasswordChanged = this.onPasswordChanged.bind(this)
    this.onPasswordConfirmationChanged = this.onPasswordConfirmationChanged.bind(this)
    this.onFullNameChanged = this.onFullNameChanged.bind(this)
    this.onDiscountCodeChanged = this.onDiscountCodeChanged.bind(this)
    this.onPlanCodeChanged = this.onPlanCodeChanged.bind(this)
    this.handleStripeToken = this.handleStripeToken.bind(this)
  }

  onContinue(event) {
    event.preventDefault()
    if (this.validateStep()) {
      let nextStep = null
      switch(this.state.step) {
        case this.steps.user:
          nextStep = this.steps.company;
          break
        case this.steps.company:
          nextStep = this.steps.billing;
          break
      }
      if (nextStep) {
        this.setState({step: nextStep})
      }
    } else {
      notify.show('Please fill out and make sure all fields are valid', 'error')
    }
  }

  validateStep() {
    switch(this.state.step) {
      case this.steps.user:
        return this.validateField('email') && this.validateField('password') && this.validateField('passwordConfirmation') && this.state.password == this.state.passwordConfirmation
      case this.steps.company:
        return this.validateField('name') && this.validateField('street') && this.validateField('city') && this.validateField('state') && this.validateField('zip') && this.validateField('fullName')
    }
    return true
  }

  validateField(name) {
    return this.state[name] != null && this.state[name] != ''
  }

  onChange(event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  onEmailChanged(event) {
    this.setState({email: event.target.value})
  }

  onPasswordChanged(event) {
    this.setState({password: event.target.value})
  }

  onPasswordConfirmationChanged(event) {
    this.setState({passwordConfirmation: event.target.value})
  }

  onFullNameChanged(event) {
    this.setState({fullName: event.target.value})
  }

  onDiscountCodeChanged(event) {
    this.setState({discountCode: event.target.value})
  }

  onPlanCodeChanged(event) {
    this.setState({planCode: event.value})
  }

  handleStripeToken(token) {
    Auth.signUp({
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation,
      full_name: this.state.fullName,
      company_attributes: {
        name: this.state.name,
        city: this.state.city,
        street: this.state.street,
        state: this.state.state,
        zip: this.state.zip,
        phone: this.state.phone,
        stripe_token: token,
        discount_code: this.state.discountCode,
        plan_code: this.state.planCode,
        created_by_email: this.state.email
      }
    })
      .then((json) => {
        if (!!json.data && json.data.id !== null) {
          notify.show('Signed up - please check your email to confirm your user', 'success')
          this.setState({step: this.steps.complete})
        } else {
          notify.show(`Error signing up - ${json.errors.full_messages.join(', ')}`, 'error')
        }
      })
      .catch((error) => {
        notify.show(`Error signing up - ${error}`, 'error')
      })
  }

  renderSignedUp() {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="card-title text-center">Sign up successful</h4>
          <p className="small"><em>Check your email for a confirmation link, then login to continue setup.</em></p>
        </div>
      </div>
    )
  }

  renderUserFields() {
    return (
      <div className="card">
        <div className="card-body">
        <h4 className="text-center">Try it free for your first location.</h4>
          <form onSubmit={this.onContinue}>
            <div className="form-group">
              <input value={this.state.email} onChange={this.onEmailChanged} type="email" className="form-control form-control-lg" id="email" aria-describedby="emailHelp" placeholder="Enter email" />
            </div>
            <div className="form-group">
              <input value={this.state.password} onChange={this.onPasswordChanged} type="password" className="form-control form-control-lg" id="password" placeholder="Password" />
            </div>
            <div className="form-group">
              <input value={this.state.passwordConfirmation} onChange={this.onPasswordConfirmationChanged} type="password" className="form-control form-control-lg" id="password_confirmation" placeholder="Confirm password" />
            </div>
            <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={this.onContinue}>Continue</button>
          </form>
          <br />
          <p className="small"><em>We will never share your email with third parties.</em></p>
          <br />
          <Link to="/sign-in">Already a user? Sign in!</Link>
        </div>
      </div>
    )
  }

  renderCompanyFields() {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="text-center">Company Info</h4>
          <form onSubmit={this.onContinue}>
            <CompanyFields onChange={this.onChange} />
            <h4 className="text-center">Company Admin</h4>
            <div className="form-group">
              <input value={this.state.fullName} onChange={this.onFullNameChanged} type="text" className="form-control form-control-lg" id="full_name" aria-describedby="emailHelp" placeholder="Full Name" />
            </div>
            <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={this.onContinue}>Sign up</button>
          </form>
          <br />
          <p className="small"><em>We will never share your email with third parties.</em></p>
          <br />
          <Link to="/sign-in">Already a user? Sign in!</Link>
        </div>
      </div>
    )
  }

  renderBillingFields() {
    return (
      <div>
        <Elements>
          <StripeBilling onDiscountCodeChanged={this.onDiscountCodeChanged} onPlanCodeChanged={this.onPlanCodeChanged} handleStripeToken={this.handleStripeToken} />
        </Elements>
        <p className="small"><em>All credit card data is securely processed though our partner, Stripe.</em></p>
        <p className="small"><em>By signing up, you agree to our <a href="https://linecheck.app/tos" target="_blank">Terms of Service</a>.</em></p>
      </div>
    )
  }

  render () {
    let fields = null
    switch(this.state.step) {
      case this.steps.user:
        fields = this.renderUserFields()
        break
      case this.steps.company:
        fields = this.renderCompanyFields()
        break
      case this.steps.billing:
        fields = this.renderBillingFields()
        break
      case this.steps.complete:
        fields = this.renderSignedUp()
        break
    }
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-md-11">
            {fields}
          </div>
        </div>
      </>
    );
  }
}

export default SignUp
