import React from "react"
import { notify } from "react-notify-toast"
import { Prompt } from "react-router"

class ItemForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      item: props.item || {},
      didChange: false
    }

    this.onPromptChanged = this.onPromptChanged.bind(this)
    this.onTypeChanged = this.onTypeChanged.bind(this)
    this.onConfigChanged = this.onConfigChanged.bind(this)
    this.onRequiresPhotoChanged = this.onRequiresPhotoChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSkip = this.onSkip.bind(this)
  }

  validate() {
    return !(
      this.state.item.prompt == null ||
      this.state.item.prompt === '' ||
      this.state.item.item_type == null ||
      this.state.item.item_type === ''
    )
  }

  onSubmit(event) {
    event.preventDefault()

    if (this.validate()) {
      this.props.onNext(this.state.item)
    } else {
      notify.show('Please check fields', 'error')
    }
  }

  onSkip(event) {
    event.preventDefault()

    this.props.onNext(null, true)
  }

  onPromptChanged(event) {
    const { item } = this.state
    item.prompt = event.target.value
    this.setState({ item })
    this.setState({didChange: true})
  }

  onTypeChanged(event) {
    const { item } = this.state
    item.item_type = event.target.value
    item.config = []
    this.setState({ item })
    this.setState({didChange: true})
  }

  onConfigChanged(event) {
    const { item } = this.state
    item.config = event.target.value.split('\n')
    this.setState({ item })
    this.setState({didChange: true})
  }

  onRequiresPhotoChanged(event) {
    const { item } = this.state
    item.requires_photo = event.target.checked
    this.setState({ item })
    this.setState({didChange: true})
  }

  render () {
    let textPrompt = "Let's create your first checklist item."
    if (this.props.list.items_attributes.length > 0) {
      textPrompt = "Add a checklist item"
    }
    let config_field = null
    switch (this.state.item.item_type) {
      case 'selection':
      case 'multiple_selection':
        config_field = (
          <div className="form-group">
            <label for=""><h6>Items for selection:</h6></label>
            <textarea value={(this.state.item.config || []).join('\n')} onChange={this.onConfigChanged} className="form-control form-control-lg" id="config" aria-describedby="emailHelp" placeholder="One item per line" />
          </div>
        )
        break
      case 'quantity':
        config_field = (
          <div className="form-group">
            <label for=""><h6>Unit for quantity:</h6></label>
            <input value={(this.state.item.config || []).join('\n')} onChange={this.onConfigChanged} type="text" className="form-control form-control-lg" id="config" aria-describedby="emailHelp" placeholder="Unit for quantity" />
          </div>
        )
        break
    }
    return (
      <>
        <Prompt
          when={this.state.didChange}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">{textPrompt}</h4>
                      <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <label htmlFor=""><h6>Prompt:</h6></label>
                          <input value={this.state.item.prompt} onChange={this.onPromptChanged} type="text" className="form-control form-control-lg" id="prompt" aria-describedby="emailHelp" placeholder="" />
                        </div>
                        <div className="form-group">
                          <label htmlFor=""><h6>Type of answer:</h6></label>
                          <select value={this.state.item.item_type} onChange={this.onTypeChanged} className="form-control form-control-lg" id="type">
                            <option>Choose one...</option>
                            <option value="text">Text</option>
                            <option value="expanded_text">Expanded text</option>
                            <option value="yes_no">Yes/No</option>
                            <option value="selection">Selection</option>
                            <option value="multiple_selection">Multiple selection</option>
                            <option value="quantity">Quantity</option>
                            <option value="temperature">Temperature</option>
                          </select>
                        </div>
                        {config_field}
                        <div className="form-check">
                          <input checked={this.state.item.requires_photo} onChange={this.onRequiresPhotoChanged} className="form-check-input" type="checkbox" id="requiresPhoto" />
                          <label className="form-check-label" for="requiresPhoto">
                            Requires photo evidence?
                          </label>
                        </div>
                        <br />
                        <button type="submit" className="btn btn-primary btn-lg btn-block">Next</button>
                        {this.props.list.id != null && (<button onClick={this.onSkip} className="btn btn-light btn-lg btn-block">Skip</button>)}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default ItemForm
