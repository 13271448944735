const API = {
  handleHttpErrors: function(response) {
    if (response.status === 500 || response.status === 401) {
      throw Error(response.statusText)
    }
    return response
  },
  handleJsonErrors: function(json) {
    if (json.error) {
      throw Error(json.message)
    }
    return json
  },
  http: function(user, endpoint, method, body, contentType) {
    let opts = {
      method: method,
      headers: {}
    }
    if (contentType) {
      opts.headers['Content-Type'] = contentType
    }
    if (user) {
      opts.headers['token-type'] = 'Bearer'
      opts.headers['access-token'] = user.access_token
      opts.headers['client'] = user.client
      opts.headers['uid'] = user.uid
    }
    if (body) {
      if (contentType === 'application/json') {
        opts.body = JSON.stringify(body)
      } else {
        opts.body = body
      }
    }
    const url = `/api/${endpoint}`
    console.log('API.http', url, opts)
    return fetch(url, opts)
      .then(this.handleHttpErrors)
  },

  httpJson: function(user, endpoint, method, body) {
    return this.http(user, endpoint, method, body, 'application/json')
      .then(response => response.json())
      .then(this.handleJsonErrors)
  },

  get: function(user, endpoint) {
    return this.httpJson(user, endpoint, 'GET')
  },

  post: function(user, endpoint, body) {
    return this.httpJson(user, endpoint, 'POST', body)
  },

  put: function(user, endpoint, body) {
    return this.httpJson(user, endpoint, 'PUT', body)
  },

  delete: function(user, endpoint) {
    return this.httpJson(user, endpoint, 'DELETE')
  },

  upload: function(user, endpoint, body) {
    return this.http(user, endpoint, 'POST', body)
  }
}

export default API