import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Notifications, { notify } from "react-notify-toast"
import { StripeProvider } from "react-stripe-elements"
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import Employee from "../services/employee"
import Sidebar from "./Sidebar"
import Session from "../services/session"
import Account from "../pages/Account"
import Dashboard from "../pages/Dashboard"
import EditEmployee from "../pages/EditEmployee"
import EditLocation from "../pages/EditLocation"
import Employees from "../pages/Employees"
import ErrorPage from "../pages/ErrorPage"
import ForgotPassword from "../pages/ForgotPassword"
import GettingStarted from "../pages/GettingStarted"
import Home from "../pages/Home"
import List from "../pages/List"
import ListFlow from "../pages/ListFlow"
import Location from "../pages/Location"
import Locations from "../pages/Locations"
import LocationCompletions from "../pages/LocationCompletions"
import LocationScores from "../pages/LocationScores"
import Log from "../pages/Log"
import Logs from "../pages/Logs"
import NewEmployee from "../pages/NewEmployee"
import NewLocation from "../pages/NewLocation"
import NewSchedule from "../pages/NewSchedule"
import NotFound from "../components/NotFound"
import Pricing from "../pages/Pricing"
import Schedules from "../pages/Schedules"
import SignIn from "../pages/SignIn"
import SignUp from "../pages/SignUp"
import Templates from "../pages/Templates"

class App extends React.Component {
  constructor(props) {
    super(props)

    const user = Session.getCurrentUser()
    this.state = {
      isLoggedIn: user != null,
      currentUser: user
    }

    this.setCurrentUser = this.setCurrentUser.bind(this)

    if (['staging', 'production'].includes(props.env)) {
      this.bugsnagClient = bugsnag(props.bugsnag_key)
      this.bugsnagClient.use(bugsnagReact, React)
    }
  }

  setCurrentUser(user) {
    console.log('setCurrentUser', user)
    if (user === null) {
      this.setState({isLoggedIn: false, currentUser: null})
    } else {
      Employee.setTimeZone(user, jstz.determine().name())
      this.setState({isLoggedIn: true, currentUser: user})
    }
    Session.setCurrentUser(user)
  }

  render () {
    const app = (
      <StripeProvider apiKey={this.props.stripe_key}>
        <Router>
          <div className="layout layout-nav-side">
            <div className="navbar navbar-expand-lg bg-dark navbar-dark sticky-top">
              <a className="navbar-brand" href="/">
                <img alt="Pipeline" src="/assets/img/linecheck_icon_white_small.png" width="50" height="50" />
              </a>
              <Sidebar {...this.state} setCurrentUser={this.setCurrentUser} />
            </div>

            <div className="main-container">
              <div className="container">
                <ErrorPage bugsnagClient={this.bugsnagClient} env={this.props.env}>
                  <Notifications options={{zIndex: 2000}}/>
                  <Switch>
                    <Route path="/" exact render={(routeProps) => <Home {...routeProps} {...this.state} />} />
                    <Route path="/dashboard" render={(routeProps) => <Dashboard {...routeProps} {...this.state} />} />
                    <Route path="/sign-in" render={(routeProps) => <SignIn {...routeProps} setCurrentUser={this.setCurrentUser} {...this.state} />} />
                    <Route path="/sign-up" render={(routeProps) => <SignUp {...routeProps} {...this.state} />} />
                    <Route path="/forgot-password" render={(routeProps) => <ForgotPassword {...routeProps} {...this.state} />} />
                    <Route path="/locations" exact render={(routeProps) => <Locations {...routeProps} {...this.state} />} />
                    <Route path="/locations/new" render={(routeProps) => <NewLocation {...routeProps} {...this.state} />} />
                    <Route path="/locations/:id/edit" render={(routeProps) => <EditLocation {...routeProps} {...this.state} />} />
                    <Route path="/locations/:location_id/lists/new" render={(routeProps) => <ListFlow {...routeProps} {...this.state} />} />
                    <Route path="/locations/:location_id/lists" render={(routeProps) => <Dashboard {...routeProps} {...this.state} />} />
                    <Route path="/locations/:location_id/completions" render={(routeProps) => <LocationCompletions {...routeProps} {...this.state} />} />
                    <Route path="/locations/:id" render={(routeProps) => <Location {...routeProps} {...this.state} />} />
                    <Route path="/locationscores" render={(routeProps) => <LocationScores {...routeProps} {...this.state} />} />
                    <Route path="/lists/:list_id/logs/:id" render={(routeProps) => <Log {...routeProps} {...this.state} />} />
                    <Route path="/lists/:list_id/logs" render={(routeProps) => <Logs {...routeProps} {...this.state} />} />
                    <Route path="/lists/:id/edit" render={(routeProps) => <ListFlow {...routeProps} {...this.state} />} />
                    <Route path="/lists/:id" render={(routeProps) => <List {...routeProps} {...this.state} />} />
                    <Route path="/schedules" exact render={(routeProps) => <Schedules {...routeProps} {...this.state} />} />
                    <Route path="/schedules/new" exact render={(routeProps) => <NewSchedule {...routeProps} {...this.state} />} />
                    <Route path="/templates" exact render={(routeProps) => <Templates {...routeProps} {...this.state} />} />
                    <Route path="/employees" exact render={(routeProps) => <Employees {...routeProps} {...this.state} />} />
                    <Route path="/employees/new" exact render={(routeProps) => <NewEmployee {...routeProps} {...this.state} />} />
                    <Route path="/employees/:id/edit" exact render={(routeProps) => <EditEmployee {...routeProps} {...this.state} />} />
                    <Route path="/account" exact render={(routeProps) => <Account {...routeProps} {...this.state} />} />
                    <Route path="/gettingstarted" render={(routeProps) => <GettingStarted {...routeProps} {...this.state} />} />
                    <Route path="/pricing" render={(routeProps) => <Pricing {...routeProps} {...this.state} />} />
                    <Route component={NotFound} />
                  </Switch>
                </ErrorPage>
              </div>
            </div>
          </div>
        </Router>
      </StripeProvider>
    )

    return app
  }
}

export default App
