import React from "react"
import { notify } from "react-notify-toast"
import { Elements } from "react-stripe-elements"
import LoggedInPage from "./LoggedInPage"
import StripeBilling from "../components/StripeBilling"
import CompanyFields from "../components/CompanyFields"
import Company from "../services/company"
import { formatDateFromUNIX } from "../services/utils"
import { capitalize } from "../services/utils"
import ButtonMailto from "../components/ButtonMailto"

class Account extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      discountCode: '',
      planCode: '',
      isLoading: false,
      ...this.props.currentUser.company
    }
    this.state.discountCode = this.props.currentUser.company.discount_code
    this.state.planCode = this.props.currentUser.company.plan_code

    this.onChange = this.onChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.handleStripeToken = this.handleStripeToken.bind(this)
  }

  onChange(event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  onSave(event, args) {
    event.preventDefault()

    Company.update(this.props.currentUser, {
      name: this.state.name,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      phone: this.state.phone
    }).then((json) => {
      notify.show('Updated company details', 'success')
      this.updateUser(json)
    }).catch((error) => {
      notify.show(`Unable to update company details - ${error}`, 'error')
    })
  }
  
  handleStripeToken(token) {
    Company.update(this.props.currentUser, {
      discount_code: this.state.discountCode,
      plan_code: this.state.planCode,
      stripe_token: token
    }).then((json) => {
      notify.show('Updated billing details', 'success')
      this.updateUser(json)
    }).catch((error) => {
      notify.show(`Unable to update billing details - ${error}`, 'error')
    })
  }

  updateUser(user) {
    this.props.currentUser.company = user
    this.props.setCurrentUser(props.currentUser)
  }

  render () {
    let invoices = (
      <p>Loading...</p>
    )
    if (!this.state.isLoading) {
      if (this.props.currentUser.invoice_list) {
        invoices = this.props.currentUser.invoice_list.data.map((invoice) => {
          return (
            <p><strong>Invoice#: {invoice.number}</strong> <br /> Amount Due: ${invoice.amount_due} <br /> Date: {formatDateFromUNIX(invoice.date, 'date_only')} <br /> Status: {capitalize(invoice.status)}</p>
          )
        })
      } else {
        invoices = (
          <p>No invoices found.</p>
        )
      }
    }

    return (
      <>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title text-center">Account Settings</h4>
            <form onSubmit={this.onSave}>
              <CompanyFields company={this.state} onChange={this.onChange} />
              <button type="submit" className="btn btn-primary btn-lg btn-block">Save</button>
            </form>
          </div>
        </div>
    
        {this.props.currentUser && this.props.currentUser.role == "admin" && this.props.currentUser.stripe_info && this.props.currentUser.stripe_info.quantity > 0 &&
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-center">Current Billing</h4>
              <p><strong>Billing Interval: </strong> {capitalize(this.props.currentUser.stripe_info.plan.interval)}</p>
              <p><strong>Location Count: </strong> {this.props.currentUser.stripe_info.quantity}</p>
              <p><strong>Next Invoice: </strong> ${(this.props.currentUser.upcoming_invoice.amount_due/100).toFixed(2)}</p>
              <p><strong>Next Invoice Due: </strong> {formatDateFromUNIX(this.props.currentUser.upcoming_invoice.next_payment_attempt, 'date_only')}</p>
              <hr />
              <p><strong>Last 3 Invoices: </strong></p>
              {invoices}
            </div>
          </div>             
        }

        {this.props.currentUser.role == "admin" &&
          <Elements>
            {/*<StripeBilling planCode={this.state.planCode} onPlanCodeChanged={this.onChange} handleStripeToken={this.handleStripeToken} submitText="Save" />*/}
            <StripeBilling discountCode={this.state.discountCode} onDiscountCodeChanged={this.onChange} planCode={this.state.planCode} handleStripeToken={this.handleStripeToken} submitText="Save" />
          </Elements>
        }
        
        {this.props.currentUser.role == "admin" &&
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-center"><font color="red">Cancel Account?</font></h4>
                <ButtonMailto label="Email Support to Cancel" mailto="mailto:support@linecheck.app" />
            </div>
          </div>
        }
      </>
    )
  }
}

export default Account
