import React from "react"
import { Link } from "react-router-dom"
import LoggedInPage from "./LoggedInPage"
import PageHeader from "../components/PageHeader"
import ReactTable from 'react-table'
import "react-table/react-table.css"
import Completion from "../services/completion"
import { daysAgo, formatDateForRange } from "../services/utils";

class LocationCompletions extends LoggedInPage {
  constructor(props) {
    super(props)

    this.state = {
      from: formatDateForRange(daysAgo(new Date(), 7)),
      to: formatDateForRange(new Date()),
      location_id: props.match.params.location_id,
      completions: [],
      isLoading: false,
    }

    this.queryCompletions();
  }

  queryCompletions() {
    this.setState({ isLoading: true });
    Completion.forLocation(
      this.props.currentUser,
      this.state.location_id,
      this.state.from,
      this.state.to
    ).then((json) => {
      this.setState({ completions: json, isLoading: false })
    }).catch((error) => {
      console.log("[Completion.forLocation] error", error)
    });
  }

  componentDidMount() {
    this.setupDatePickers()
  }

  setupDatePickers() {
    flatpickr("input[data-flatpickr='true']", {
      onChange: (dates, date_str, instance) => {
        this.setState({ [instance.element.id]: date_str });
        this.queryCompletions();
      },
    });
  }

  renderCompletionTable() {
    if (this.state.isLoading) {
      return <p>Loading...</p>;
    }

    const columns = [{
      Header: 'List',
      accessor: 'list'
    }, {
      Header: 'Monday',
      accessor: 'monday'
    }, {
      Header: 'Tuesday',
      accessor: 'tuesday'
    }, {
      Header: 'Wednesday',
      accessor: 'wednesday'
    }, {
      Header: 'Thursday',
      accessor: 'thursday'
    }, {
      Header: 'Friday',
      accessor: 'friday'
    }, {
      Header: 'Saturday',
      accessor: 'saturday'
    }, {
      Header: 'Sunday',
      accessor: 'sunday'
    }, {      
      Header: 'Average',
      accessor: 'average'
    }];

    const { completions } = this.state;

    return (
      <ReactTable
        data={completions}
        columns={columns}
        defaultSorted={[
          {
            id: "average",
            desc: true
          }
        ]}
        defaultPageSize={10}
        className="-striped -highlight"
      />
    );
  }

  renderDatePicker(field) {
    return (
      <input
        defaultValue={this.state[field]}
        className="form-control small inline"
        type="text"
        id={field}
        data-flatpickr
        data-alt-input="true"
        data-date-format="Y-m-d" />
    );
  }

  render() {
    const dateSelector = (
      <div>
        Date Range: {this.renderDatePicker('from')} to {this.renderDatePicker('to')}
      </div>
    );

    return (
      <>
        <PageHeader title="Location Completions" currentUser={this.props.currentUser} />
        <div>
          {dateSelector}
          <br />
          {this.renderCompletionTable()}
          <br />
          <Link to={`/locations/${this.state.location_id}`}>Back to location</Link>
        </div>
      </>
    );
  }
}

export default LocationCompletions



