import React from "react"

class ItemPrompt extends React.Component {
  constructor(props) {
    super(props)

    this.onAnotherItem = this.onAnotherItem.bind(this)
    this.onNoMoreItems = this.onNoMoreItems.bind(this)
  }

  onAnotherItem(event) {
    event.preventDefault()

    this.props.onItemPromptResponse(true)
  }

  onNoMoreItems(event) {
    event.preventDefault()

    this.props.onItemPromptResponse(false)
  }

  render () {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="row justify-content-center">
                <div className="col-11 col-md-11">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-center">Would you like to add another item to your checklist?</h4>
                      <form>
                        <button onClick={this.onAnotherItem} className="btn btn-primary btn-lg btn-block">Yes</button>
                        <button onClick={this.onNoMoreItems} className="btn btn-primary btn-lg btn-block">I'm done</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default ItemPrompt
