import React from "react"
import { notify } from "react-notify-toast"
import Moment from 'react-moment';
import 'moment-timezone';

class ScheduleForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      schedule: (props.list.schedules && props.list.schedules[0]) || (props.list.schedules_attributes && props.list.schedules_attributes[0]) || {},
      datesAreValid: true
    }

    this.onScheduleTypeChanged = this.onScheduleTypeChanged.bind(this)
    this.onDaysChanged = this.onDaysChanged.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.checkEndsAt = this.checkEndsAt.bind(this)
  }

  onSubmit(event) {
    event.preventDefault()
    this.checkEndsAt()
    if (this.state.datesAreValid == false) {
      return false
    }
    if (this.validate()) {
      this.props.onSubmit && this.props.onSubmit(this.state.schedule)
    } else {
      notify.show('Please make sure all fields are filled out', 'error')
    }
  }

  validate() {
    return !(
      this.state.schedule.starts_at == null ||
      this.state.schedule.starts_at === '' ||
      this.state.schedule.schedule_type == null ||
      this.state.schedule.schedule_type === '' ||
      this.state.schedule.schedule_type === 'daily' && (this.state.schedule.days == null || this.state.schedule.days === '')
      )
  }

  onScheduleTypeChanged(event) {
    const { schedule } = this.state
    schedule.schedule_type = event.target.value
    this.setState({ schedule })
  }

  onDaysChanged(event) {
    const { schedule } = this.state
    schedule.days = [...$("input[name='days']:checked")].map(item => item.value).join(",")
    this.setState({ schedule })
  }

  checkEndsAt(event) {
    if (typeof this.state.schedule.ends_at !== 'undefined' && this.state.schedule.ends_at !== '') {
      if (this.state.schedule.ends_at < this.state.schedule.starts_at) {
        this.setState({datesAreValid: false})
        notify.show('The Ends at date cannot be before Starts at date', 'error')
      } else {
        this.setState({datesAreValid: true})
      }
    } else {
      this.setState({datesAreValid: true})
    }
  }

  componentDidMount() {
    this.setupDatePickers()
  }

  setupDatePickers() {
    flatpickr("input[data-flatpickr='true']", {
      onChange: (dates, date_str, instance) => {
        const { schedule } = this.state
        schedule[instance.element.id] = date_str
        this.setState({ schedule })
        console.log(dates)
      },
      onClose: (dates, date_str, instance) => {
        this.checkEndsAt()
      },
      onReady: function(dateObj, dateStr, instance) {
        var $cal = $(instance.calendarContainer);
        if ($cal.find('.flatpickr-clear').length < 1) {
            $cal.append('<div class="flatpickr-clear">Clear</div>');
            $cal.find('.flatpickr-clear').on('click', function() {
            instance.clear();
            instance.close();
          });
        }
      }
    });
  }

  isDaySelected(day) {
    return this.state.schedule && this.state.schedule.days && this.state.schedule.days.split(',').includes(day)
  }

  render() {
    let days = null
    if (this.state.schedule.schedule_type === 'daily') {
      days = (
        <>
          <div className="form-group">
            <label for="days">Choose days</label>
          </div>
          <div className="form-group">
            <p style={{'clear': 'both'}}>
              <input value="mo" checked={this.isDaySelected('mo')} onChange={this.onDaysChanged} type="checkbox" className="form-control" id="days_mo" name="days" aria-describedby="emailHelp" style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
              <label for="days_mo" style={{'float': 'left', 'marginTop': '4px'}}>Monday</label>
            </p>
            <p style={{'clear': 'both'}}>
              <input value="tu" checked={this.isDaySelected('tu')} onChange={this.onDaysChanged} type="checkbox" className="form-control" id="days_tu" name="days" aria-describedby="emailHelp" style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
              <label for="days_tu" style={{'float': 'left', 'marginTop': '4px'}}>Tuesday</label>
            </p>
            <p style={{'clear': 'both'}}>
              <input value="we" checked={this.isDaySelected('we')} onChange={this.onDaysChanged} type="checkbox" className="form-control" id="days_we" name="days" aria-describedby="emailHelp" style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
              <label for="days_we" style={{'float': 'left', 'marginTop': '4px'}}>Wednesday</label>
            </p>
            <p style={{'clear': 'both'}}>
              <input value="th" checked={this.isDaySelected('th')} onChange={this.onDaysChanged} type="checkbox" className="form-control" id="days_th" name="days" aria-describedby="emailHelp" style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
              <label for="days_th" style={{'float': 'left', 'marginTop': '4px'}}>Thursday</label>
            </p>
            <p style={{'clear': 'both'}}>
              <input value="fr" checked={this.isDaySelected('fr')} onChange={this.onDaysChanged} type="checkbox" className="form-control" id="days_fr" name="days" aria-describedby="emailHelp" style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
              <label for="days_fr" style={{'float': 'left', 'marginTop': '4px'}}>Friday</label>
            </p>
            <p style={{'clear': 'both'}}>
              <input value="sa" checked={this.isDaySelected('sa')} onChange={this.onDaysChanged} type="checkbox" className="form-control" id="days_sa" name="days" aria-describedby="emailHelp" style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
              <label for="days_sa" style={{'float': 'left', 'marginTop': '4px'}}>Saturday</label>
            </p>
            <p style={{'clear': 'both'}}>
              <input value="su" checked={this.isDaySelected('su')} onChange={this.onDaysChanged} type="checkbox" className="form-control" id="days_su" name="days" aria-describedby="emailHelp" style={{'float': 'left', 'width': '30px', 'height': '30px'}} />
              <label for="days_su" style={{'float': 'left', 'marginTop': '4px'}}>Sunday</label>
            </p>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="form-group">
          <select name="schedule_type" className="form-control form-control-lg" onChange={this.onScheduleTypeChanged} value={this.state.schedule.schedule_type}>
            <option>Choose a schedule type...</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        <div className="form-group">
          <label for="">Start Date</label>
          <input value={this.state.schedule.starts_at} className="form-control" type="text" id="starts_at" 
            data-flatpickr
            data-alt-input="true"
            data-enable-time="true"
            data-date-format="Y-m-d H:i" />
        </div>
        <div className="form-group">
          <label for="">End date (optional)</label>
          <input value={this.state.schedule.ends_at} className="form-control" type="text" id="ends_at" 
            data-flatpickr
            data-alt-input="true"
            data-enable-time="true"
            data-
            data-date-format="Y-m-d H:i" />
        </div>
        {days}
        <button type="submit" className="btn btn-primary btn-lg btn-block" onClick={this.onSubmit}>Save</button>
      </>
    );
  }
}

export default ScheduleForm
