import React from "react"
import { Link } from "react-router-dom"
import { notify } from "react-notify-toast"
import Auth from "../services/auth"

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: ''
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onEmailChanged = this.onEmailChanged.bind(this)
  }

  onSubmit(event) {
    event.preventDefault();
    let accessToken = null
    let client = null
    Auth.requestTemporaryPassword(this.state.email)
      .then(() => {
        this.setState({ email: '' })
        notify.show('Temporary password requested, please check your email', 'success')
      })
      .catch((error) => {
        notify.show(`Error requesting temporary password - ${error}`, 'error')
      })
  }

  onEmailChanged(event) {
    this.setState({email: event.target.value});
  }

  render () {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-md-11">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-center">Request a temporary password below.</h4>
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <input value={this.state.email} onChange={this.onEmailChanged} type="email" className="form-control form-control-lg" id="email" aria-describedby="emailHelp" placeholder="Enter email" />
                  </div>
                  <button type="submit" className="btn btn-primary btn-lg btn-block">Request</button>
                </form>
                <Link to="/sign-up">Need to create an account? Sign up!</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ForgotPassword
