const SessionKey = '_CHECKLIST_USER'

const Session = {
  setCurrentUser: function(user) {
    if (user == null) {
      localStorage.removeItem(SessionKey)
      sessionStorage.removeItem(SessionKey)
    } else {
      if (user.remember_me) {
        localStorage.setItem(SessionKey, JSON.stringify(user))
      } else {
        localStorage.removeItem(SessionKey)
      }
      sessionStorage.setItem(SessionKey, JSON.stringify(user))
    }
  },

  getCurrentUser: function() {
    return JSON.parse(localStorage.getItem(SessionKey) || sessionStorage.getItem(SessionKey))
  }
}

export default Session;