import API from './api'

const Log = {
  allForLocation: function(user, location_id) {
    return API.get(user, `locations/${location_id}/logs`)
  },

  all: function(user, list_id) {
    return API.get(user, `lists/${list_id}/logs`)
  },

  find: function(user, list_id, id) {
    return API.get(user, `lists/${list_id}/logs/${id}`)
  },

  create: function(user, list_id, responses) {
    return API.post(user, `lists/${list_id}/logs`, { log: { responses } })
  },

  update: function(user, log, responses) {
    return API.put(user, `lists/${log.list_id}/logs/${log.id}`, { log: { responses } })
  },

  uploadEvidence: function(user, log, item_id, evidence) {
    const formData = new FormData()
    formData.append('evidence[item_id]', item_id)
    formData.append('evidence[content]', evidence)
    return API.upload(user, `lists/${log.list_id}/logs/${log.id}/evidences`, formData)
  }
}

export default Log