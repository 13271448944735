import API from './api'

const Employee = {
  all: function(user) {
    return API.get(user, 'users')
  },

  find: function(user, id) {
    return API.get(user, `users/${id}`)
  },

  create: function(user, email, full_name, role) {
    return API.post(user, 'users', { user: { email, full_name, role } })
  },

  update: function(user, employee, email, full_name, role, password, password_confirmation, deactivated) {
    const data = { user: { email, full_name, role, deactivated} }
    if (user.id === employee.id) {
      data.user.password = password
      data.user.password_confirmation = password_confirmation
    }
    return API.put(user, `users/${employee.id}`, data)
  },

  setTimeZone: function(user, time_zone) {
    return API.put(user, `users/${user.id}`, { user: { time_zone: time_zone }})
  }
}

export default Employee