import React from "react"
import SignUp from "../components/SignUp"

class Home extends React.Component {
  constructor(props) {
    super(props)

    if (props.isLoggedIn) {
      props.history && props.history.push('/dashboard')
    }
  }
  render () {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-11 col-xl-9">
            <section className="py-4 py-lg-5">
              <div className="mb-3 d-flex">
                <img alt="Linecheck" src="/assets/img/linecheck.png" height="50" style={{'marginTop': '10px'}} />
              </div>
              <h1 className="display-4 mb-3">Execution software to help you automate your restaurant's checklists.</h1>
              <p className="lead">
                Never miss another line check without knowing about it. Customize your checklists. Set schedules. Get notified when your checklists are missed and completed. <a href="https://linecheck.app/" target="_blank"><font color="red">Learn more.</font></a>
              </p>
              <hr />
              <SignUp />
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default Home
